import { ApplicationId } from '../onboarding';
import { Underwriting } from './common';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';

export const UnderwritingPhInventoryAssets = T.OurEnum(
  {
    adequate: { title: 'Visible and Adequate' },
    inadequate: { title: 'Inadequate/Not Visible/Not Applicable' },
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhLoanPurposeStatus = T.OurEnum(
  {
    clear: {},
    notClear: {},
    uncertain: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhUnderwriterOpinion = T.OurEnum(
  {
    veryBad: {},
    bad: {},
    moderate: {},
    good: {},
    veryGood: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhOverall = PrismaModel(
  {
    name: 'UnderwritingPhOverall',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
  },
  {
    underwritingId: ApplicationId,
    tin: T.Nullable(T.String({ title: 'TIN' })),
    operationStart: T.Nullable(T.LocalDate({ title: 'Year of operation start' })),
    loanPurpose: T.Nullable(T.String()),
    loanPurposeStatus: T.Nullable(UnderwritingPhLoanPurposeStatus),
    inventoryAssets: T.Nullable(UnderwritingPhInventoryAssets),
    underwriterOpinion: T.Nullable(UnderwritingPhUnderwriterOpinion),
  },
);

export const RelUnderwritingPhOverallUnderwriting = PrismaRelation(
  UnderwritingPhOverall,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phOverall',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingPhCashCycle = PrismaModel(
  {
    name: 'UnderwritingPhCashCycle',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
  },
  {
    underwritingId: ApplicationId,
    paymentTermsSuppliers: T.Nullable(T.Integer({ title: 'Payment Terms with Suppliers (days)' })),
    paymentTermsCustomers: T.Nullable(T.Integer({ title: 'Payment Terms with Customers (days)' })),
    goodsSellTime: T.Nullable(T.Integer({ title: 'Time to sell goods / deliver service (days)' })),
    receivablesTotal: T.Nullable(T.Integer({ title: 'Total Receivables from Aging List' })),
    receivablesOnTime: T.Nullable(T.Integer({ title: 'On Time Receivables from Aging List' })),
    receivablesLate0to30Days: T.Nullable(T.Integer({ title: 'Receivables late 0-30 days from Aging List' })),
    receivablesLate30to60Days: T.Nullable(T.Integer({ title: 'Receivables late 30-60 days from Aging List' })),
  },
);
export const RelUnderwritingPhCashCycleUnderwriting = PrismaRelation(
  UnderwritingPhCashCycle,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phCashCycle',
  ['id'],
  'Nullable',
);

export const UnderwritingPhObligationInformationSource = T.OurEnum(
  {
    cibiReport: {},
    selfDeclared: {},
    other: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhObligationType = T.OurEnum(
  {
    autoLoan: {},
    creditCard: {},
    unsecuredLoan: {},
    securedLoan: {},
    other: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhObligation = PrismaModel(
  {
    name: 'UnderwritingPhObligation',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    underwritingId: ApplicationId,
    type: UnderwritingPhObligationType,
    informationSource: UnderwritingPhObligationInformationSource,
    loanAmount: T.Money(),
    loanDuration: T.Integer(),
    remainingLoanDuration: T.Integer(),
    monthlyRepayment: T.Money(),
  },
);
export const RelUnderwritingPhObligationUnderwriting = PrismaRelation(
  UnderwritingPhObligation,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phObligations',
  ['id'],
  'Array',
  'Cascade',
);

export const UnderwritingPhReferenceCheckFeedbackQuality = T.OurEnum(
  {
    positive: {},
    neutral: {},
    negative: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhReferenceCheckValidation = T.OurEnum(
  {
    validated: {},
    notValidated: {},
    weakValidation: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhReferenceCheck = PrismaModel(
  {
    name: 'UnderwritingPhReferenceCheck',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    underwritingId: ApplicationId,
    name: T.String(),
    phone: T.String(),
    email: T.String(),
    validation: UnderwritingPhReferenceCheckValidation,
    feedback: T.String(),
    feedbackQuality: UnderwritingPhReferenceCheckFeedbackQuality,
  },
);
export const RelUnderwritingPhReferenceCheckUnderwriting = PrismaRelation(
  UnderwritingPhReferenceCheck,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phReferenceChecks',
  ['id'],
  'Array',
  'Cascade',
);

export const UnderwritingPhAfs = PrismaModel(
  {
    name: 'UnderwritingPhAfs',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    underwritingId: ApplicationId,
    endingPeriod: T.LocalDate(),
    revenue: T.Nullable(T.Money()),
    costOfGoodsSold: T.Nullable(T.Money()),
    netIncome: T.Nullable(T.Money({ title: 'Net Income / Profit' })),
    cash: T.Nullable(T.Money()),
    accountsReceivables: T.Nullable(T.Money()),
    inventory: T.Nullable(T.Money()),
    totalCurrentAssets: T.Nullable(T.Money()),
    totalAssets: T.Nullable(T.Money()),
    tradePayables: T.Nullable(T.Money()),
    totalShortTermLiabilities: T.Nullable(T.Money()),
    totalLiabilities: T.Nullable(T.Money()),
  },
);

export const RelUnderwritingPhAfsUnderwriting = PrismaRelation(
  UnderwritingPhAfs,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phAfs',
  ['id'],
  'Array',
  'Cascade',
);

export const PhBankId = T.IdNumber();
export const PhBank = PrismaModel(
  {
    name: 'PhBank',
    dbSchema: 'public',
    key: ['id'],
  },
  {
    id: PhBankId,
    title: T.String(),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
  },
);
export const UnderwritingPhBankAccountId = T.IdNumber();
export const UnderwritingPhBankAccount = PrismaModel(
  {
    name: 'UnderwritingPhBankAccount',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(UnderwritingPhBankAccountId),
    underwritingId: ApplicationId,
    bankId: { ...PhBankId, title: 'Bank' },
    accountNumber: T.String(),
  },
);
export const RelUnderwritingPhBankAccountBank = PrismaRelation(
  UnderwritingPhBankAccount,
  'bank',
  ['bankId'],
  '',
  PhBank,
  'underwritingBankAccount',
  ['id'],
  'Array',
);
export const UnderwritingPhBankAccountMonthData = PrismaModel(
  {
    name: 'UnderwritingPhBankAccountMonthData',
    dbSchema: 'backoffice',
    key: ['bankAccountId', 'month'],
  },
  {
    bankAccountId: UnderwritingPhBankAccountId,
    month: T.YearMonth(),
    totalCredits: T.Money(),
    totalDebits: T.Money(),
    endingBalance: T.Money(),
    dailyAverageBalance: T.Money(),
  },
);
export const RelUnderwritingPhBankAccountMonthDataBankAccount = PrismaRelation(
  UnderwritingPhBankAccountMonthData,
  'bankAccount',
  ['bankAccountId'],
  '',
  UnderwritingPhBankAccount,
  'monthData',
  ['id'],
  'Array',
  'Cascade',
);
export const RelUnderwritingPhBankAccountUnderwriting = PrismaRelation(
  UnderwritingPhBankAccount,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phBankAccounts',
  ['id'],
  'Array',
  'Cascade',
);

export const UnderwritingPhSalesReport = PrismaModel(
  {
    name: 'UnderwritingPhSalesReport',
    dbSchema: 'backoffice',
    key: ['underwritingId', 'month'],
  },
  {
    underwritingId: ApplicationId,
    month: T.YearMonth({ title: 'Reporting month' }),
    sales: T.Money(),
    cogs: T.Money({ title: 'COGS' }),
    opex: T.Money(),
    payroll: T.Money(),
    rentalAndUtilities: T.Money({ title: 'Rental & Utilities' }),
    otherExpenses: T.Money(),
  },
);
export const RelUnderwritingPhSalesReportUnderwriting = PrismaRelation(
  UnderwritingPhSalesReport,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phSalesReports',
  ['id'],
  'Array',
  'Cascade',
);
