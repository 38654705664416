import { log } from '../../utils/log';
import { R } from '../../vendor/remeda';
import type {
  ArrayInputtableSchema,
  FormatFunction,
  FormatOptions,
  FormattableSchema,
  IfDefined,
  InputtableSchema,
  MaybeObjectFormatOptions,
  RichTypeOpts,
} from '../base';
import {
  createType,
  format,
  FormatFuncKind,
  isArrayInputtable,
  isFormattable,
  isPrismaType,
  LogTransformKind,
  PrismaTypeKind,
  VueArrayInputComponentKind,
  VueInputComponentKind,
} from '../base';
import * as Type from '@sinclair/typebox';
import { h } from 'vue';

export interface TArray<T extends Type.TSchema>
  extends Type.TArray<T>,
    FormattableSchema<
      MaybeObjectFormatOptions<
        IfDefined<FormatOptions<T>> & {
          arraySeparator?: string;
        }
      >
    >,
    InputtableSchema<T extends ArrayInputtableSchema<infer P> ? P : never> {}

export const { IsArray } = Type.TypeGuard;

const richOpts = {
  [LogTransformKind]: ((value: unknown, schema: Type.TSchema) => {
    if (!Array.isArray(value)) {
      return value;
    }
    return value.map((v) => log.cleanExtraValue(schema.items, v));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any,
  [VueInputComponentKind]: (props) =>
    isArrayInputtable(props.schema.items) && h(props.schema.items[VueArrayInputComponentKind], props as never),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies RichTypeOpts<TArray<any>>;

const TypeArray = <T extends Type.TSchema>(itemSchema: T, o?: Type.SchemaOptions): TArray<T> =>
  createType(Type.Array(itemSchema, { ...R.pick(itemSchema, ['title', 'description']), ...o }), {
    [FormatFuncKind]: isFormattable(itemSchema)
      ? (((value, opts, schema) =>
          value
            .map((item) => format(schema.items as never, item as never, opts as never))
            .join((opts as { arraySeparator: string } | undefined)?.arraySeparator ?? ', ')) as FormatFunction<
          TArray<T>,
          object
        >)
      : (undefined as never),
    [PrismaTypeKind]: isPrismaType(itemSchema)
      ? R.splice(itemSchema[PrismaTypeKind].split(' '), 1, 0, ['[]', '@default([])']).join(' ')
      : undefined,
    ...richOpts,
  } as never);
export { TypeArray as Array };
