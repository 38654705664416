import type { InputtableSchema, RichTypeOpts, SimpleFormattable } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  VueInputComponentKind,
} from '../base';
import * as Type from '@sinclair/typebox';
import type { InputTextProps } from 'primevue/inputtext';

export { type StringOptions } from '@sinclair/typebox';

export interface TString extends Type.TString, SimpleFormattable, InputtableSchema<InputTextProps> {}

export const { IsString } = Type.TypeGuard;

const richOpts = {
  [FormatFuncKind]: (value) => value,
  [PrismaTypeKind]: 'String',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/inputtext'),
    (C) => C,
  ),
} satisfies RichTypeOpts<TString>;
export const String = (opts?: Type.StringOptions): TString => createType(Type.String(opts), richOpts);
