import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { yup } from '../../../utils/yup';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { defaultMimeTypes as defaultMimes } from '../../types/mimeType';
import { useApplication } from '../../../auth/applicationContext';
import { $Enums } from '@getmo/common/prisma';
import { R } from '@getmo/common/vendor/remeda';
import { MFAST_ROSTFIX, Utm, UtmSource } from '@getmo/common/schemas/models/utm';
import { Value } from '@getmo/common/vendor/typebox';

interface InitialValues {
  files: {
    businessPermit: FullDocument[];
  };
}

const PhCompanyInformation: FC = () => {
  const title = <>Company Information</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const { application, setApplication } = useApplication();
  const initialValues = useMemo<InitialValues>(
    () => ({
      files: {
        businessPermit: application.documents.filter(
          (d) => d.type === $Enums.DocumentType.businessPermit && !d.coownerId,
        ),
      },
    }),
    [application.documents],
  );

  const utm = application.utm ? Value.Decode(Utm, application.utm) : undefined;
  const isPermitRequired = utm?.source !== UtmSource.fieldSales || utm.campaign?.endsWith(MFAST_ROSTFIX) || false;

  const validation = Yup.object({
    files: Yup.object({
      businessPermit: Yup.array().min(isPermitRequired ? 1 : 0, yup.uploadMinFile(1)),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      const files = R.values(values.files).flat();
      const uploadIds = files.map((u) => u.id);
      setApplication((a) => ({
        ...a,
        documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
      }));

      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <DocumentFileInput
                    name="files.businessPermit"
                    label={
                      application.qualification === $Enums.OnboardingQualification.highTicket
                        ? "Mayor's permit"
                        : 'Barangay Business Permit/Barangay Clearance'
                    }
                    docType={$Enums.DocumentType.businessPermit}
                    mimeTypes={defaultMimes.businessPermit}
                    required={isPermitRequired}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhCompanyInformation;
