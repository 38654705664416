import { Decimal } from '../../prisma';
import { currency } from '../../utils/formats/currency';
import type { FormattableSchema, InputtableSchema, RichTypeOpts, SimpleFormattable } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  VueInputComponentKind,
  VueTableCellComponentKind,
} from '../base';
import { numberFormatter } from '../vendorType';
import * as Type from '../vendorType';
import type { $Enums } from '@prisma/client';
import * as RawType from '@sinclair/typebox';
import type { InputTextProps } from 'primevue/inputtext';
import { h } from 'vue';

export const percentFormatter = Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 3 });

export interface TPercent extends SimpleFormattable, Type.TNumber {
  numberFormat: 'percent';
}
export const IsPercent = (schema: unknown): schema is TPercent =>
  Type.IsNumber(schema) && schema.numberFormat === 'percent';
const percentRichOpts = {
  [FormatFuncKind]: (value) => percentFormatter.format(value / 100),
  [PrismaTypeKind]: 'Float /// [Percent]',
} satisfies RichTypeOpts<TPercent>;
export const Percent = (opts?: Type.NumberOptions): TPercent =>
  createType(Type.Number({ numberFormat: 'percent', unit: '%', ...opts }), percentRichOpts);
export const IntPercent = (opts?: Type.NumberOptions): TPercent =>
  createType(Type.Number({ numberFormat: 'percent', unit: '%', ...opts }), {
    [FormatFuncKind]: (value) => percentFormatter.format(value / 100),
    [PrismaTypeKind]: 'Int /// [Percent]',
  });

export interface TDecimal
  extends SimpleFormattable,
    Type.TTransform<Type.TNumber, Decimal>,
    InputtableSchema<Omit<InputTextProps, 'modelValue'>> {
  numberFormat: 'decimal';
}
const decimalRichOpts = {
  [FormatFuncKind]: (value) => numberFormatter.format(value.toNumber()),
  [PrismaTypeKind]: 'Decimal',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('@getmo/common/components/InputMoney.vue'),
    (C) => (props) => h(C, { autocomplete: 'off', ...props }),
  ),
} satisfies RichTypeOpts<TDecimal>;
export const IsDecimal = (schema: unknown): schema is TDecimal =>
  Type.IsNumber(schema) && schema.numberFormat === 'decimal';
const TypeDecimal = (opts?: Type.NumberOptions): TDecimal =>
  createType(
    Type.Transform(Type.Number({ ...opts, numberFormat: 'decimal' }))
      .Decode((v) => new Decimal(v))
      .Encode((v) => v.toNumber()),
    decimalRichOpts,
  );
export { TypeDecimal as Decimal };

export interface TDecimalString extends SimpleFormattable, Type.TTransform<Type.TString, Decimal> {}
export const DecimalString = (opts?: Type.NumberOptions): TDecimalString =>
  createType(
    Type.Transform(Type.String({ ...opts }))
      .Decode((v) => new Decimal(v))
      .Encode((v) => v.toString()),
    {
      [FormatFuncKind]: (value) => numberFormatter.format(value.toNumber()),
    },
  );

export interface TMoney
  extends Type.TTransform<Type.TNumber, Decimal>,
    FormattableSchema<{ region: $Enums.Region | null }>,
    InputtableSchema<Omit<InputTextProps, 'modelValue'>> {
  numberFormat: 'percent';
  money: true;
}
export const IsMoney = (schema: unknown): schema is TMoney =>
  Type.IsNumber(schema) && schema.numberFormat === 'decimal' && schema.money;
const moneyRichOpts = {
  [FormatFuncKind]: (value, options) => currency.formatMoney(value, options),
  [VueTableCellComponentKind]: createAsyncComponentWrapper(
    () => import('./MoneyTableCell.vue'),
    (C) => C,
  ),
  [PrismaTypeKind]: 'Decimal /// [Money]',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('@getmo/common/components/InputMoney.vue'),
    (C) => (props) => h(C, { autocomplete: 'off', ...props }),
  ),
} satisfies RichTypeOpts<TMoney>;
export const Money = (opts?: Type.NumberOptions): TMoney =>
  createType(TypeDecimal({ ...opts, money: true }), moneyRichOpts);

export const SmallInt = (opts?: Type.NumberOptions): Type.TNumber =>
  createType(Type.Number(opts), { [PrismaTypeKind]: 'Int @db.SmallInt' });

export const IdNumber = (opts?: Type.NumberOptions): RawType.TNumber =>
  createType(RawType.Number(opts), { [PrismaTypeKind]: 'Int' });

const humanIdNumberRichOpts = {
  [VueTableCellComponentKind]: createAsyncComponentWrapper(
    () => import('./HumanIdNumberTableCell.vue'),
    (C) => C,
  ),
} satisfies RichTypeOpts<Type.TNumber>;
export const HumanIdNumber = (opts?: Type.NumberOptions): Type.TNumber =>
  createType(Type.Integer(opts), humanIdNumberRichOpts);

export const AutoIncrement = (schema: RawType.TNumber): RawType.TNumber =>
  createType(schema, {
    [PrismaTypeKind]: 'Int @default(autoincrement())',
  });
