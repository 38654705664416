import { PrismaModel, PrismaRelation, T } from '../../typebox';
import { Loan } from './loans';
import { ApplicationId, Onboarding } from './onboarding';
import { Underwriting, UnderwritingStatus } from './underwriting';

export const DailyLoanValues = PrismaModel(
  {
    name: 'DailyLoanValues',
    dbSchema: 'analytics',
    key: ['loanId', 'date'],
    description: 'Historical values for each day of a loan',
  },
  {
    loanId: ApplicationId,
    date: T.LocalDate(),
    dpd: T.Integer(),
    duePrincipalAmount: T.Money(),
    dueInterestAmount: T.Money(),
    duePenaltyAmount: T.Money(),
    outstandingPrincipalAmount: T.Money(),
    outstandingInterestAmount: T.Money(),
    repaidPrincipalAmount: T.Money(),
    repaidInterestAmount: T.Money(),
    repaidPenaltyAmount: T.Money(),
  },
);
export const RelDailyLoanValuesLoan = PrismaRelation(
  DailyLoanValues,
  'loan',
  ['loanId'],
  '',
  Loan,
  'dailyValues',
  ['id'],
  'Array',
  'Cascade',
);

export const BankTransactionSummary = PrismaModel(
  {
    name: 'BankTransactionSummary',
    dbSchema: 'analytics',
    key: ['onboardingId'],
  },
  {
    onboardingId: ApplicationId,
    avgMonthlyCreditAmount: T.Money(),
  },
);
export const RelBankTransactionSummaryOnboarding = PrismaRelation(
  BankTransactionSummary,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'bankTransactionSummary',
  ['id'],
  'Nullable',
  'Cascade',
);

export const LoanDailyOverdue = PrismaModel(
  {
    name: 'LoanDailyOverdue',
    dbSchema: 'analytics',
    key: ['loanId', 'date'],
    description:
      'DPD. Due Borrowers. For each due borrower: each date from his due period + dpd at this date + amount due at this date',
  },
  {
    loanId: ApplicationId,
    date: T.LocalDate({
      description: 'all the dates when the borrower has an unpaid invoice',
    }),
    dpd: T.Integer({ description: 'dpd by the date' }),
    amount: T.Money({ description: 'total amount due by the date' }),
    principalAmount: T.Money(),
    interestAmount: T.Money(),
    penaltyAmount: T.Money(),
  },
);
export const RelLoanDailyOverdueLoan = PrismaRelation(
  LoanDailyOverdue,
  'loan',
  ['loanId'],
  '',
  Loan,
  'dailyOverdues',
  ['id'],
  'Array',
  'Cascade',
);

export const LoanHistoryIntervalValues = PrismaModel(
  {
    name: 'LoanHistoryIntervalValues',
    dbSchema: 'analytics',
    key: ['loanId', 'daysOnBooks'],
  },
  {
    loanId: ApplicationId,
    daysOnBooks: T.Integer(),
    cumulativeRepaidAmount: T.Money(),
  },
);
export const RelLoanHistoryIntervalValuesLoan = PrismaRelation(
  LoanHistoryIntervalValues,
  'loan',
  ['loanId'],
  '',
  Loan,
  'historyIntervalValues',
  ['id'],
  'Array',
  'Cascade',
);

export const UnderwritingStageTimings = PrismaModel(
  {
    name: 'UnderwritingStageTimings',
    dbSchema: 'analytics',
    key: ['underwritingId', 'stage'],
    description: 'Time spent by applications in each underwriting status.',
  },
  {
    underwritingId: T.Integer(),
    stage: UnderwritingStatus,
    hoursInStatus: T.Number({
      description: 'Total time (working and not working hours)',
    }),
    hoursInRevision: T.Number({
      description: 'Time in revision  (working and not working hours)',
    }),
    workingHoursInStatus: T.Number({
      description: 'Only working hours spent in status',
    }),
  },
);
export const RelUnderwritingStageTimingsUnderwriting = PrismaRelation(
  UnderwritingStageTimings,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'stageTimings',
  ['id'],
  'Array',
  'Cascade',
);
