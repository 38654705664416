import { Employee, EmployeeId } from './auth';
import { Region } from './common';
import { Loan, SaleChannel, VirtualAccount } from './loans';
import { ApplicationId } from './onboarding';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';

export const RepaymentSource = T.OurEnum(
  {
    danabijak: {},
    dragonpay: {},
    manual: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const RepaymentId = T.IdNumber();
export const Repayment = PrismaModel(
  {
    name: 'Repayment',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['source', 'externalId']],
    description: 'Income money transaction',
  },
  {
    id: T.AutoIncrement(RepaymentId),
    createdAt: T.CreatedAt({ description: 'Date and time of record creation' }),
    phone: T.Nullable(T.FullPhone()),
    date: T.Date({ description: 'Transaction date' }),
    amount: T.Money({
      description: 'Total amount paid in one transaction (may include payments for several invoices)',
    }),
    email: T.Nullable(T.String()),
    region: Region,
    source: RepaymentSource,
    externalId: T.Nullable(T.String()),
    description: T.String({ default: '' }),
    isBeforeInstamoney: T.Boolean({
      description: 'Repayment loaded from Payment Data spreadsheet but missing in Instamoney',
      default: false,
    }),
    vaNumber: T.String(),
  },
);
export const RelRepaymentVirtualAccount = PrismaRelation(
  Repayment,
  'virtualAccount',
  ['vaNumber'],
  '',
  VirtualAccount,
  'repayments',
  ['number'],
  'Array',
);

export const RepaymentScheduleStatus = T.OurEnum(
  {
    pendingInvoice: {},
    pendingRevenue: {},
    unpaid: {},
    paidOnTime: {},
    paidLate: {},
    restructured: {},
    writtenOff: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const RepaymentSchedule = PrismaModel(
  {
    name: 'RepaymentSchedule',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['loanId', 'date']],
    description:
      'Repayment plan for a loan. Schedules are created in the moment of disbursement and can be corrected by submitted Invoices.',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    loanId: ApplicationId,
    date: T.LocalDate({ description: 'Invoice date' }),
    number: T.SmallInt(),
    amount: T.Money({ description: 'Total invoice amount' }),
    principalAmount: T.Money(),
    interestAmount: T.Money(),
    penaltyAmount: T.Money(),
    penaltyRebateAmount: T.Money(),
    interestRebateAmount: T.Money(),
    calculatedPenaltyAmount: T.Money(),
    paidAmount: T.Money({
      description: 'Paid amount correspondent to each invoice ',
      default: 0,
    }),
    paymentDate: T.Nullable(T.Date()),
    status: RepaymentScheduleStatus,
    isHiddenForCollection: T.Boolean({ default: false }),
  },
);
export const RelRepaymentScheduleLoan = PrismaRelation(
  RepaymentSchedule,
  'loan',
  ['loanId'],
  '',
  Loan,
  'repaymentSchedules',
  ['id'],
  'Array',
  'Cascade',
);

export const Invoice = PrismaModel(
  {
    name: 'Invoice',
    dbSchema: 'backoffice',
    key: ['loanId', 'date'],
  },
  {
    loanId: ApplicationId,
    date: T.LocalDate({
      description: 'Scheduled date from Repayment Schedule',
    }),
    baseAmount: T.Money(),
    isCollectedByAmg: T.Boolean({ default: false }),
  },
);
export const RelInvoiceSchedule = PrismaRelation(
  Invoice,
  'schedule',
  ['loanId', 'date'],
  '',
  RepaymentSchedule,
  'invoice',
  ['loanId', 'date'],
  'Nullable',
);
export const RelInvoiceLoan = PrismaRelation(
  Invoice,
  'loan',
  ['loanId'],
  '',
  Loan,
  'invoices',
  ['id'],
  'Array',
  'Cascade',
);

export const CustomerSaleChannelRevenue = PrismaModel(
  {
    name: 'CustomerSaleChannelRevenue',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['loanId', 'date', 'saleChannel']],
    description: 'For RBF loans',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    loanId: ApplicationId,
    date: T.LocalDate(),
    saleChannel: T.Nullable(SaleChannel),
    amount: T.Money(),
  },
);
export const RelCustomerSaleChannelRevenueInvoice = PrismaRelation(
  CustomerSaleChannelRevenue,
  'invoice',
  ['loanId', 'date'],
  '',
  Invoice,
  'saleChannelRevenues',
  ['loanId', 'date'],
  'Array',
  'Cascade',
);

export const InvoiceAdditionType = T.OurEnum(
  {
    prepayment: {},
    penalty: {},
    rebate: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const InvoiceAddition = PrismaModel(
  {
    name: 'InvoiceAddition',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    loanId: ApplicationId,
    date: T.LocalDate(),
    type: InvoiceAdditionType,
    title: T.String(),
    amount: T.Money(),
  },
);
export const RelInvoiceAdditionInvoice = PrismaRelation(
  InvoiceAddition,
  'invoice',
  ['loanId', 'date'],
  '',
  Invoice,
  'additions',
  ['loanId', 'date'],
  'Array',
  'Cascade',
);

export const RepaymentAssignment = PrismaModel(
  {
    name: 'RepaymentAssignment',
    dbSchema: 'backoffice',
    key: ['repaymentId', 'loanId', 'repaymentScheduleDate'],
    description: 'Part of an incoming repayment assigned to a repayment schedule',
  },
  {
    repaymentId: RepaymentId,
    loanId: { ...ApplicationId, description: 'Loan ID the repayment is assigned to' },
    repaymentScheduleDate: T.LocalDate({
      description: 'Invoice (repayment scheduled) date that the repayment is assigned to',
    }),
    principalAmount: T.Money(),
    interestAmount: T.Money(),
    penaltyAmount: T.Money(),
    amount: T.Money({
      description:
        'Sum of principal, interests and penalties assigned to a specific Loan ID and a specific invoice date',
    }),
    collectorId: T.Nullable(EmployeeId),
  },
);
export const RelRepaymentAssignmentRepaymentSchedule = PrismaRelation(
  RepaymentAssignment,
  'repaymentSchedule',
  ['loanId', 'repaymentScheduleDate'],
  '',
  RepaymentSchedule,
  'assignments',
  ['loanId', 'date'],
  'Array',
  'Cascade',
);
export const RelRepaymentAssignmentRepayment = PrismaRelation(
  RepaymentAssignment,
  'repayment',
  ['repaymentId'],
  '',
  Repayment,
  'assignments',
  ['id'],
  'Array',
  'Cascade',
);
export const RelRepaymentAssignmentCollector = PrismaRelation(
  RepaymentAssignment,
  'collector',
  ['collectorId'],
  'Nullable',
  Employee,
  'collectedRepayments',
  ['id'],
  'Array',
);
