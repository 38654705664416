import { CircularProgress } from '@mui/material';

const Finish = () => {
  const destination = '/review';
  if (window === window.parent) {
    window.location.href = 'https://www.pondopinoy.ph' + destination;
  } else {
    window.parent.postMessage(['navigate', destination], '*');
  }

  return <CircularProgress />;
};

export default Finish;
