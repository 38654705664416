/* eslint-disable no-console */
/* eslint-disable n/no-process-env */
/* eslint-disable import/no-named-as-default-member */
import { hasCustomLogTransform, LogTransformKind } from '../typebox/base';
import { DF } from '@getmo/common/vendor/date-fns';
import { H3Error } from '@getmo/common/vendor/h3';
import { captureException, captureMessage } from '@sentry/core';
import type { TSchema } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';
import type { Logger } from 'log4js';
import log4js from 'log4js';
import pc from 'picocolors';
import stringify from 'safe-stable-stringify';
// eslint-disable-next-line unicorn/prefer-node-protocol
import util from 'util';

const { NODE_ENV } = process.env;
const isDev = NODE_ENV === 'development';

const serializeError = (e: Error) => {
  const extra: Record<string, unknown> = { ...e };
  delete extra.message;
  delete extra.stack;
  delete extra.cause;

  let cause = e.cause;
  if (cause instanceof Error) {
    const { extra: causeExtra, error: causeError } = serializeError(cause);

    cause = causeError;
    Object.assign(extra, { cause: causeExtra });
  }

  return {
    extra,
    error: {
      message: e.message,
      stack: e.stack,
      cause,
    },
  };
};
const serializeEvent = (data: unknown[]) => {
  const extra: Record<string, unknown> = {};
  const message: string[] = [];

  for (const d of data) {
    if (!d) {
      continue;
    }

    if (typeof d === 'string') {
      message.push(d);
    } else if (d instanceof Error) {
      message.push(d.message);
    } else {
      Object.assign(extra, d);
    }
  }
  return { extra, message: message.join('; ') };
};

const colors = pc.createColors(true);
// eslint-disable-next-line unicorn/consistent-function-scoping
log4js.addLayout('colored-custom', () => (loggingEvent) => {
  let prefix = util.format(
    '[%s] [%s] %s - ',
    DF.format(loggingEvent.startTime, 'yyyy-MM-dd, HH:mm:ss.SSS'),
    String(loggingEvent.level),
    loggingEvent.categoryName,
  );

  const color = loggingEvent.level.colour;
  const colorize = color in colors ? colors[color as keyof typeof colors] : null;
  if (typeof colorize === 'function') {
    prefix = colorize(prefix);
  }

  return (
    prefix +
    util.formatWithOptions(
      {
        colors: true,
        depth: 5,
        compact: false,
        maxStringLength: 100,
      },
      ...loggingEvent.data.filter(Boolean),
    )
  );
});
log4js.addLayout('json', ({ separator = '' }) => (loggingEvent) => {
  const data: Record<string, unknown> = { ...loggingEvent };
  let errorFields: unknown;
  if (loggingEvent.error) {
    const { extra, error } = serializeError(loggingEvent.error);
    errorFields = extra;
    data.error = error;
  }

  Object.assign(data, serializeEvent(loggingEvent.data.concat(errorFields)));
  delete data.data;

  return stringify(data) + separator;
});

log4js.configure({
  categories: {
    default: {
      appenders: [import.meta.server ? 'stdout' : 'console'],
      level: 'info',
    },
  },
  appenders: {
    stdout: {
      type: 'stdout',
      layout: { type: isDev ? 'colored-custom' : 'json' },
    },
    console: {
      type: {
        // eslint-disable-next-line unicorn/consistent-function-scoping
        configure: () => (event) => {
          const { level, data: _data } = event;
          const data = _data.filter(Boolean);

          switch (level.levelStr) {
            case 'ERROR':
              console.error(...data);
              break;
            case 'WARN':
              console.warn(...data);
              break;
            case 'INFO':
              console.info(...data);
              break;
            default:
              console.log(...data);
              break;
          }
        },
      },
    },
  },
});

type LogFn = (message: string, extra?: Record<string, unknown>) => void;
type ErrorLogFn = LogFn &
  ((error: unknown, extra?: Record<string, unknown>) => void) &
  ((error: unknown, message?: string, extra?: Record<string, unknown>) => void);
type LogFns = {
  trace: LogFn;
  debug: LogFn;
  info: LogFn;
  warn: LogFn;
  fatal: LogFn;
  mark: LogFn;
  error: ErrorLogFn;
};
const logger = log4js.getLogger() as Omit<Logger, keyof LogFns> & LogFns;

export const log = {
  info: (message: string, props?: Record<string, unknown>) => logger.info(message, props),
  warn: (message: string, props?: Record<string, unknown>) => logger.warn(message, props),
  error: (message: string, props?: Record<string, unknown>) => {
    captureMessage(message, { extra: props ?? {} });
    logger.error(message, props);
  },
  exception: (ex: unknown, props?: Record<string, unknown>) => {
    captureException(ex instanceof H3Error && ex.cause instanceof Error ? ex.cause : ex, {
      level: ex instanceof H3Error && ex.statusCode < 500 ? 'warning' : 'error',
      ...(ex instanceof H3Error && {
        contexts: {
          response: {
            status_code: ex.statusCode,
          },
        },
      }),
      extra: props ?? {},
    });
    logger.error(ex, props);
  },

  cleanExtraValue: (schema: TSchema, _value: unknown): unknown => {
    if (hasCustomLogTransform(schema) && Value.Check(schema, _value)) {
      return schema[LogTransformKind](_value, schema);
    }

    let value = _value;
    if (Array.isArray(value) && value.length > 7) {
      value = value.slice(0, 7).concat('## and more... ##');
    }

    if (value instanceof Buffer || value instanceof Blob) {
      return '## binary ##';
    }

    return value;
  },
};
