import { log } from '../../utils/log';
import type { RichTypeOpts } from '../base';
import { createType, LogTransformKind } from '../base';
import * as Type from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';

export { type TUnion } from '@getmo/common/vendor/typebox';

export const { IsUnion } = Type.TypeGuard;

const richOpts = {
  [LogTransformKind]: (value, schema) => {
    for (const inner of schema.anyOf) {
      if (Value.Check(inner, value)) {
        return log.cleanExtraValue(inner, value);
      }
    }
    return value;
  },
} satisfies RichTypeOpts<Type.Union<never[]>>;
export const Union = <const T extends Type.TSchema[]>(properties: T, opts?: Type.SchemaOptions): Type.TUnion<T> =>
  createType(Type.Union(properties, opts), richOpts);
