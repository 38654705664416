import { R } from '../../vendor/remeda';
import type { ObjectOnlyPathForSchema } from '../util';
import { getSchemaPath } from '../util';
import * as Type from '../vendorType';
import { CustomType } from './custom';

export const Path = <T extends Type.TSchema>(objSchema: T) =>
  CustomType<ObjectOnlyPathForSchema<T>>(Type.String(), (v) => {
    if (typeof v !== 'string') return false;
    let s: Type.TSchema | null = objSchema;
    const parts: string[] = R.stringToPath(v);
    for (const key of parts) {
      s = getSchemaPath(s, key);
      if (!s) return false;
    }
    return true;
  });
