import type { Money } from '../../typebox';
import { T } from '../../typebox';
import type { CurrencyFormatOptions } from './currency';
import { getUnitBySchema } from './units';
import { DF } from '@getmo/common/vendor/date-fns';
import { R } from '@getmo/common/vendor/remeda';
import type { StaticDecode, TSchema } from '@sinclair/typebox';
import { titleCase } from 'scule';

export const Char = {
  NBSP: '\u00A0',
} as const;

export const getSchemaTitle = (schema: TSchema) => {
  let usedSchema = schema;
  if (!schema.title && T.IsUnion(schema) && !T.IsEnum(schema)) {
    usedSchema = schema.anyOf.find((s) => !!s.title) ?? schema;
  }

  return usedSchema.title;
};
export const genFieldTitle = (path: string, schema: TSchema) =>
  getSchemaTitle(schema) ?? titleCase((R.stringToPath(path) as string[]).at(-1) ?? '');
export const genFieldTitleWithUnit = (path: string, schema: TSchema) => {
  const title = genFieldTitle(path, schema);
  const unit = getUnitBySchema(schema);
  return unit ? `${title}, ${unit}` : title;
};

export type SchemaFormatOptions<T extends TSchema> =
  StaticDecode<T> extends Money | null ? CurrencyFormatOptions : unknown;

export { format as formatWithSchema } from '../../typebox/base';
export { dateFormat, dateTimeFormat } from '../../typebox/ourType/date';
export { percentFormatter } from '../../typebox/ourType/numbers';
export { formatEnumValue } from '../../typebox/vendorType/enum';
export { numberFormatter } from '../../typebox/vendorType/number';
export { genLiteralSchemaTitle } from './literal';

export const formatLocalDatePeriod = (period: readonly [Date, Date]) =>
  `${DF.format(period[0], 'dd MMM')} - ${DF.format(period[1], 'dd MMM yyyy')}`;

export const formatYearMonth = (date: Date) => DF.format(date, 'yyyy-MM');

const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' });
const ordinalSuffixes: Record<Intl.LDMLPluralRule, string> = {
  zero: '',
  one: 'st',
  two: 'nd',
  few: 'rd',
  many: 'th',
  other: 'th',
};
export const formatOrdinal = (n: number) => {
  const rule = pluralRules.select(n);
  const suffix = ordinalSuffixes[rule];
  return `${n}${suffix}`;
};
