import { PrismaModel, T } from '../../typebox';

export const Currency = T.OurEnum(
  {
    usd: {},
    eur: {},
    idr: {},
    php: {},
  },
  {
    dbSchema: 'public',
  },
);

export const Region = T.OurEnum(
  {
    id: { title: 'Indonesia' },
    ph: { title: 'Phillipines' },
  },
  {
    dbSchema: 'public',
  },
);

export const Gender = T.OurEnum(
  {
    male: {},
    female: {},
  },
  {
    dbSchema: 'public',
  },
);

export const CurrencyRate = PrismaModel(
  {
    name: 'CurrencyRate',
    dbSchema: 'public',
    key: ['date', 'currency'],
  },
  {
    date: T.LocalDate(),
    currency: Currency,
    rate: T.Decimal(),
  },
);

export const PhCity = T.OurEnum(
  {
    malabon: {},
    navotas: {},
    caloocan: {},
    valenzuela: {},
    taguig: {},
    paranaque: {},
    mandaluyong: {},
    pasay: {},
    pasig: {},
    makati: {},
    manila: {},
    marikina: {},
    lasPinas: {},
    qc: { title: 'Quezon City' },
    pateros: {},
    sanJuan: {},
    muntinlupa: {},
    bulacan: {},
    rizal: {},
    cavite: {},
    laguna: {},
    notNcr: { title: 'Not NCR' },
  },
  {
    dbSchema: 'public',
    title: 'Phillipine city',
  },
);

export const UnderwritingPipeline = T.OurEnum(
  {
    highTickets: {},
    midTickets: {},
    lowTickets: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
