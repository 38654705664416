import { createEndpoint } from '@getmo/common/endpoint';
import * as DB from '@getmo/common/prisma/typebox';
import { T } from '@getmo/common/typebox';
import type { StaticDecode } from '@getmo/common/vendor/typebox';

export type PersonUnverifiedData = StaticDecode<typeof personUnverifiedData>;
const personUnverifiedData = T.Composite([
  T.Pick(DB.PersonUnverifiedData, ['fullName', 'dob', 'gender', 'personalIdNumber']),
  T.Object({
    phPersonalIdType: T.Optional(DB.PersonUnverifiedData.properties.phPersonalIdType),

    // Backward compatibility
    personalIdTypePh: T.Optional(DB.PersonUnverifiedData.properties.phPersonalIdType),
  }),
]);

export const personUnverifiedDataReadEndpoint = createEndpoint({
  method: 'get',
  path: '/person/unverfieid',
  response: T.Union([
    T.Object({}),
    T.Object({ data: personUnverifiedData }),
    T.Object({ ktpOcrData: T.Pick(DB.KtpOcrData, ['fullName', 'dob', 'nik']) }),
  ]),
});

export const personUnverifiedDataUpdateEndpoint = createEndpoint({
  method: 'post',
  path: '/person/unverified',
  body: personUnverifiedData,
  response: personUnverifiedData,
});

export const personVerificationReason = T.Union([T.Literal('imgManipulationScore'), T.Literal('liveness')]);
export const personVerificationEndpoint = createEndpoint({
  method: 'post',
  path: '/person/verification',
  qs: T.Object({
    skipVidaRequest: T.Optional(T.Boolean()),
  }),
  body: T.Object({
    selfiePhoto: T.Base64(),
  }),
  response: T.Object({
    data: T.Nullable(
      T.Object({
        result: T.Nullable(T.Boolean()),
        reasons: T.Array(personVerificationReason),
      }),
    ),
  }),
});

export const personSelfieUploadEndpoint = createEndpoint({
  method: 'post',
  path: '/person/selfie/upload',
  body: T.Object({
    selfiePhoto: T.Base64(),
  }),
  response: T.Void(),
});
