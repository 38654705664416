import type { InputtableSchema, RichTypeOpts, SimpleFormattable } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  VueInputComponentKind,
} from '../base';
import * as Type from '@sinclair/typebox';
import type { InputTextProps } from 'primevue/inputtext';
import { h } from 'vue';

export const numberFormatter = Intl.NumberFormat('en-US', { style: 'decimal', useGrouping: 'min2' });

export { type NumberOptions } from '@sinclair/typebox';

export interface TNumber extends Type.TNumber, SimpleFormattable, InputtableSchema<InputTextProps> {}
export const { IsNumber } = Type.TypeGuard;
const numberRichOpts = {
  [FormatFuncKind]: (value) => numberFormatter.format(value),
  [PrismaTypeKind]: 'Float',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('@getmo/common/components/OurInputNumber.vue'),
    (C) => (props) => h(C, { autocomplete: 'off', ...props }),
  ),
} satisfies RichTypeOpts<TNumber>;
export const Number = (opts?: Type.NumberOptions): TNumber => createType(Type.Number(opts), numberRichOpts);

export interface TInteger extends Type.TNumber, SimpleFormattable, InputtableSchema<InputTextProps> {}
export const { IsNumber: IsInteger } = Type.TypeGuard;
// TODO: replace with true Integer type
const integerRichOpts = {
  [FormatFuncKind]: (value) => numberFormatter.format(value),
  [PrismaTypeKind]: 'Int',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('@getmo/common/components/OurInputNumber.vue'),
    (C) => (props) => h(C, { autocomplete: 'off', ...props }),
  ),
} satisfies RichTypeOpts<TNumber>;
export const Integer = (opts?: Type.NumberOptions): TNumber => createType(Type.Number(opts), integerRichOpts);
