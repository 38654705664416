import { Currency } from './common';
import { ApplicationId, Onboarding, OnboardingCoowner, OnboardingCoownerId } from './onboarding';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';
import { PrismaTypeKind, withRichOpts } from '@getmo/common/typebox/base';

export const Bank = T.OurEnum(
  {
    bca: { title: 'BCA' },
    bri: { title: 'BRI' },
    bni: { title: 'BNI' },
    mandiri: {},
    seabank: { title: 'SeaBank' },
    ocbc: { title: 'OCBC' },
    cimbNiaga: { title: 'CIMB Niaga' },
    other: {},
  },
  {
    dbSchema: 'public',
  },
);
export const DocumentType = T.OurEnum(
  {
    ktp: { title: 'KTP' },
    npwp: { title: 'NPWP' },
    npwpPersonal: { title: 'Personal NPWP' },
    npwpCorporate: { title: 'Corporate NPWP' },
    bankStatementPersonal: { title: 'Personal bank statement' },
    bankStatementCorporate: { title: 'Corporate bank statement' },
    sk: { title: 'SK' },
    trademarkCertificate: {},
    businessRegistration: {},
    businessPermit: {},
    companyProfile: {},
    customerInvoice3m: { title: 'Customer invoice (3 months)' },
    balance: {},
    nib: { title: 'NIB' },
    saleChannelOwnershipProof: {},
    salesData: {},
    salesScreenshot: {},
    ocr: { title: 'OCR' },
    creditHistory: {},
    accountsReceivableAgingReport: {},
    applicationForm: {},
    clientReport: {},
    other: {},

    personalId: {},
    proofOfBilling: {},
    selfie: {},
    storePhoto: {},
    taxReport: { title: 'AFS/ITR 2023' },

    loanAgreement: {},
    loanPersonalGuaranteeMainApplicant: {
      title: 'Personal guarantee of main applicant',
    },
    loanSecondPersonalGuarantee: { title: 'Second personal guarantee' },
    loanBoardOfCommissionersApproval: { title: 'BOC approval' },
    loanCircularResolutionOfShareholders: {
      title: 'Circular resolution of shareholders',
    },
    loanPostDatedCheques: { title: 'Post dated cheques' },
    loanSiteVisitReport: { title: 'Site visit report' },
  },
  {
    dbSchema: 'public',
  },
);

export const DocumentId = T.IdNumber();
export const Document = PrismaModel(
  {
    name: 'Document',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['onboardingId']],
    description: 'Document attached to an application during onboarding or in backoffice',
  },
  {
    id: T.AutoIncrement(DocumentId),
    createdAt: T.CreatedAt(),
    fileName: T.String(),
    filePath: T.String({ description: 'Google Drive id' }),
    type: DocumentType,
    onboardingId: ApplicationId,
    isDeleted: T.Boolean({ default: false }),
    isUploadedByClient: T.Boolean({
      description: 'Whether this file was uploaded by client or by employee ',
      default: false,
    }),
    originalId: T.Nullable({
      ...DocumentId,
      description: 'For copied application this field points to the original document',
    }),
    md5Checksum: T.String(),
    validationScore: T.Nullable(
      T.IntPercent({
        description: 'Filled by OpenAI prompt for personalId images',
      }),
    ),
    validationResult: withRichOpts(T.Any(), { [PrismaTypeKind]: 'Json @default("null")' }),
    coownerId: T.Nullable(OnboardingCoownerId),
    bank: T.Nullable(Bank),
    ocrStarted: T.Boolean({ default: false }),
    ocrDocId: T.Nullable(T.Integer()),
    pdfCreator: T.Nullable(T.String()),
    pdfProducer: T.Nullable(T.String()),
  },
);
export const RelDocumentOnboarding = PrismaRelation(
  Document,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'documents',
  ['id'],
  'Array',
  'Cascade',
);
export const RelDocumentOriginal = PrismaRelation(
  Document,
  'original',
  ['originalId'],
  'Nullable',
  Document,
  'document',
  ['id'],
  'Array',
);
export const RelDocumentCoowner = PrismaRelation(
  Document,
  'coowner',
  ['coownerId'],
  'Nullable',
  OnboardingCoowner,
  'documents',
  ['id'],
  'Array',
  'Cascade',
);
export const RelDocumentOcrDoc = PrismaRelation(
  Document,
  'ocrDoc',
  ['ocrDocId'],
  'Nullable',
  Document,
  'ocrSourceDocs',
  ['id'],
  'Array',
  'SetNull',
);

export const BankStatementParser = T.OurEnum(
  {
    bca: {},
    bcaBlu: {},
    bni: {},
    bniTi: {},
    bniMobile: {},
    bniIb: {},
    bri: {},
    mandiri: {},
    mandiriRk: {},
    mandiriTi: {},
    mandiriPie: {},
    mandiriNew: {},
    seabank: {},
    ocbc: {},
    ocbcCas: {},
    cimbNiaga: {},
  },
  {
    dbSchema: 'public',
  },
);
export const BankStatement = PrismaModel(
  {
    name: 'BankStatement',
    dbSchema: 'public',
    key: ['id'],
    description: 'Data parsed from bank statements',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    documentId: DocumentId,
    bank: Bank,
    startDate: T.LocalDate(),
    endDate: T.LocalDate(),
    startBalance: T.Nullable(T.Money()),
    endBalance: T.Nullable(T.Money()),
    accountNumber: T.String(),
    currency: Currency,
    clientName: T.String(),
    address: T.String(),
    isFullyParsed: T.Boolean(),
    parser: BankStatementParser,
    parsingErrorPageNumber: T.Nullable(T.Integer()),
    parsingErrorRowNumber: T.Nullable(T.Integer()),
  },
);
export const RelBankStatementDocument = PrismaRelation(
  BankStatement,
  'document',
  ['documentId'],
  '',
  Document,
  'bankStatements',
  ['id'],
  'Array',
  'Cascade',
);

export const KtpOcrData = PrismaModel(
  {
    name: 'KtpOcrData',
    dbSchema: 'public',
    key: ['documentId'],
    description: 'Indonesia only',
  },
  {
    documentId: DocumentId,
    province: T.String(),
    cityDistrict: T.String(),
    bloodGroup: T.String(),
    religion: T.String(),
    address: T.String(),
    validUntil: T.String(),
    citizenship: T.String(),
    nik: T.String(),
    fullName: T.String(),
    work: T.String(),
    placeOfBirth: T.String(),
    subdistrict: T.String(),
    gender: T.String(),
    rt: T.String(),
    rw: T.String(),
    dob: T.LocalDate(),
    maritalStatus: T.String(),
    villageSubdistrict: T.String(),
  },
);
export const RelKtpOcrDataDocument = PrismaRelation(
  KtpOcrData,
  'document',
  ['documentId'],
  '',
  Document,
  'ktpOcrData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const NpwpOcrData = PrismaModel(
  {
    name: 'NpwpOcrData',
    dbSchema: 'public',
    key: ['documentId'],
    description: 'Indonesia only',
  },
  {
    documentId: DocumentId,
    npwp: T.String(),
    name: T.String(),
    nik: T.String(),
    address: T.String(),
  },
);
export const RelNpwpOcrDataDocument = PrismaRelation(
  NpwpOcrData,
  'document',
  ['documentId'],
  '',
  Document,
  'npwpOcrData',
  ['id'],
  'Nullable',
  'Cascade',
);
