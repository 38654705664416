import type { RichTypeOpts, SimpleFormattable } from '../base';
import { createType, FormatFuncKind } from '../base';
import * as Type from '@sinclair/typebox';

export { type TLiteralValue } from '@sinclair/typebox';

export interface TLiteral<T extends Type.TLiteralValue> extends Type.TLiteral<T>, SimpleFormattable {}

export const { IsLiteral } = Type.TypeGuard;

const richOpts = {
  [FormatFuncKind]: String,
} satisfies RichTypeOpts<TLiteral<never>>;
export const Literal = <const T extends Type.TLiteralValue>(v: T, opts?: Type.SchemaOptions): TLiteral<T> =>
  createType(Type.Literal(v, opts), richOpts);
