import { phone } from '../../utils/formats/phone';
import { DF } from '../../vendor/date-fns';
import type { FormatComponent, InputComponent, SimpleFormattable } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  defineFC,
  FormatFuncKind,
  LogTransformKind,
  PrismaTypeKind,
  VueComponentKind,
  VueInputComponentKind,
} from '../base';
import { Format } from '../formats';
import * as Type from '../vendorType';
import type { DatePickerEmitsOptions } from 'primevue/datepicker';
import type { InputMaskEmitsOptions } from 'primevue/inputmask';
import { h } from 'vue';

interface StringType<F extends Format> extends Type.TString, SimpleFormattable {
  format: F;
}
const createStringType = <const F extends Format>(
  format: F,
  mods: {
    [FormatFuncKind]: (value: string) => string;
    [LogTransformKind]?: (value: string) => string;
    [VueComponentKind]?: FormatComponent<StringType<F>>;
    [VueInputComponentKind]?: InputComponent<StringType<F>, object>;
    [PrismaTypeKind]?: string;
  },
) => {
  Type.FormatRegistry.Set(format, () => true);
  return {
    Type: (opts?: Type.StringOptions): StringType<F> => createType(Type.String({ format, ...opts }), mods as never),
    Guard: (schema: unknown): schema is StringType<F> => Type.IsString(schema) && schema.format === format,
  };
};

export type TBase64 = StringType<Format.Base64>;
export const { Type: Base64, Guard: IsBase64 } = createStringType(Format.Base64, {
  [FormatFuncKind]: () => '## base64 ##',
  [LogTransformKind]: () => '## base64 ##',
});

export type TSecret = StringType<Format.Secret>;
export const { Type: Secret, Guard: IsSecret } = createStringType(Format.Secret, {
  [FormatFuncKind]: () => '## hidden value ##',
  [LogTransformKind]: () => '## hidden value ##',
});

export type TEmail = StringType<Format.Email>;
export const { Type: Email, Guard: IsEmail } = createStringType(Format.Email, {
  [FormatFuncKind]: (value) => value,
  [LogTransformKind]: (value) => {
    const [name = '', domain = ''] = value.split('@');
    return [name.length > 2 ? `${name[0]}***${name.at(-1)}` : '**', domain].join('@');
  },
  [PrismaTypeKind]: 'String /// [Email]',
});

export type TUri = StringType<Format.URI>;
export const { Type: Uri, Guard: IsUri } = createStringType(Format.URI, {
  [FormatFuncKind]: (value) => value,
  [PrismaTypeKind]: 'String /// [Uri]',
});

export type TFullPhone = StringType<Format.FullPhone>;
export const { Type: FullPhone, Guard: IsFullPhone } = createStringType(Format.FullPhone, {
  [FormatFuncKind]: (value) => phone.format(value),
  [LogTransformKind]: (value) => (value.length > 5 ? `${value.slice(0, 5)}***` : '**'),
  [PrismaTypeKind]: 'String /// [FullPhone]',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/inputmask'),
    (C) =>
      defineFC(
        (props, context) =>
          h(C, {
            mask: '+62 999-9999-99?999',
            placeholder: '+62 000-0000-0000',
            autoClear: false,
            unmask: true,
            ...props,
            modelValue: props.modelValue?.replace(/^\+62/, '') ?? '',
            'onUpdate:modelValue': (val: Parameters<InputMaskEmitsOptions['update:modelValue']>[0]) =>
              context.emit('update:modelValue', '+62' + val),
          }),
        { emits: ['update:modelValue'] },
      ),
  ),
});

export type TNpwp = StringType<Format.npwp>;
export const { Type: Npwp, Guard: IsNpwp } = createStringType(Format.npwp, {
  [FormatFuncKind]: (value) => value,
  [LogTransformKind]: (value) => (value.length > 5 ? `${value.slice(0, 5)}***` : '**'),
  [PrismaTypeKind]: 'String /// [Npwp]',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/inputmask'),
    (C) => (props) =>
      h(C, {
        mask: '99.999.999.9-999.999',
        placeholder: '00.000.000.0-000.000',
        autoClear: false,
        ...props,
      }),
  ),
});

export type TMultiline = StringType<Format.Multiline>;
export const { Type: Multiline, Guard: IsMultiline } = createStringType(Format.Multiline, {
  [FormatFuncKind]: (value) => value,
  [VueComponentKind]: ({ value }) =>
    value.split('\n').map((line, i) => h('span', { key: i }, [i === 0 ? null : h('br'), line])),
  [PrismaTypeKind]: 'String /// [Multiline]',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/textarea'),
    (C) => (props) =>
      h(C, {
        autoResize: true,
        rows: '5',
        cols: '30',
        ...props,
      }),
  ),
});

export type RichText = StringType<Format.RichText>;
export const { Type: RichText, Guard: IsRichText } = createStringType(Format.RichText, {
  [FormatFuncKind]: (value) => value,
  [VueComponentKind]: createAsyncComponentWrapper(
    () => import('@getmo/common/components/RichText.vue'),
    (Component) => Component,
  ),
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('@getmo/common/components/TipTap/index.vue'),
    (C) => C,
  ),
  [PrismaTypeKind]: 'String /// [RichText]',
});

export type YearMonth = StringType<Format.YearMonth>;
export const { Type: YearMonth, Guard: IsYearMonth } = createStringType(Format.YearMonth, {
  [FormatFuncKind]: (value) => value,
  [PrismaTypeKind]: 'String /// [YearMonth]',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/datepicker'),
    (C) =>
      defineFC(
        (props, context) =>
          h(C, {
            inputId: props.id,
            view: 'month',
            dateFormat: 'yy-mm',
            placeholder: 'yyyy-mm',
            ...props,
            id: `${props.id}_wrapper`,
            modelValue: props.modelValue ? new Date(props.modelValue) : null,
            'onUpdate:modelValue': (val: Parameters<DatePickerEmitsOptions['update:modelValue']>[0]) =>
              context.emit('update:modelValue', val && !Array.isArray(val) ? DF.format(val, 'yyyy-MM') : null),
          }),
        { emits: ['update:modelValue'] },
      ),
  ),
});

export const Uuid = (opts?: Type.StringOptions): Type.TString =>
  createType(Type.String(opts), { [PrismaTypeKind]: 'String @default(uuid()) @db.Uuid' });

export const IpAddress = (opts?: Type.StringOptions): Type.TString =>
  createType(Type.String(opts), { [PrismaTypeKind]: 'String @db.Inet' });

export const DbGeneratedString = (opts?: Type.StringOptions): Type.TString =>
  createType(Type.String(opts), { [PrismaTypeKind]: 'String @default(dbgenerated())' });
