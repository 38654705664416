import type { FormattableSchema, MaybeObjectFormatOptions, RichTypeOpts } from '../base';
import { createType, FormatFuncKind } from '../base';
import * as Type from '@sinclair/typebox';

export interface TNull
  extends Type.TNull,
    FormattableSchema<
      MaybeObjectFormatOptions<{
        fallback?: string;
      }>
    > {}

export const { IsNull } = Type.TypeGuard;

const richOpts = {
  [FormatFuncKind]: (_, { fallback = 'N/A' } = {}) => fallback,
} satisfies RichTypeOpts<TNull>;
export const Null = (opts?: Type.SchemaOptions): TNull => createType(Type.Null(opts), richOpts);
