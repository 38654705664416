import { PrismaModel, PrismaRelation, T } from '../../typebox';
import { PhCity, Region, UnderwritingPipeline } from './common';

export const UserRole = T.OurEnum(
  {
    fieldAgent: {},
    accountManager: {},
    administrator: {},
    decisionMaker: {},
    underwriter: {},
  },
  {
    dbSchema: 'backoffice',
  },
);

export const EmployeeTag = T.OurEnum(
  {
    inboundLeadsOwner: {},
    lowAndMidTicketOwner: {},
    phFieldSalesAgent: {},
    creditInvestigationAgent: {},
    fieldCollectionAgent: {},
    writeOffAccess: {},
  },
  {
    dbSchema: 'backoffice',
  },
);

export const EmployeeId = T.IdNumber();
export const Employee = PrismaModel(
  {
    name: 'Employee',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['email']],
    description: 'GetMo employee. Backoffice user.',
  },
  {
    id: T.AutoIncrement(EmployeeId),
    isActive: T.Boolean({ default: true }),
    tags: T.Array(EmployeeTag),
    email: T.Unique(T.Nullable(T.String())),
    phone: T.Unique(T.Nullable(T.String())),
    name: T.String(),
    role: UserRole,
    region: T.Nullable(Region),
    pipelines: T.Array(UnderwritingPipeline),
    phCities: T.Array(PhCity),
    geoDivisions: T.Array(T.String()),
    slackUserId: T.Nullable(T.String()),
    gmailToken: T.Nullable(T.String()),
  },
);

export const WebPushSubscription = PrismaModel(
  {
    name: 'WebPushSubscription',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    employeeId: EmployeeId,
    userAgent: T.String(),
    endpoint: T.String(),
    expirationTime: T.Nullable(T.Date()),
    auth: T.String(),
    p256dh: T.String(),
  },
);

export const RelWebPushSubscriptionEmployee = PrismaRelation(
  WebPushSubscription,
  'employee',
  ['employeeId'],
  '',
  Employee,
  'webPushSubscriptions',
  ['id'],
  'Array',
  'Cascade',
);
