import { PrismaModel, PrismaRelation, T } from '../../typebox';
import { PrismaTypeKind } from '../../typebox/base';
import { Employee, EmployeeId } from './auth';
import { Gender, PhCity, Region } from './common';

export const PhPersonalIdType = T.OurEnum(
  {
    umid: { title: 'UMID' },
    sss: { title: 'SSS' },
    passport: {},
    driversLicense: {},
    tin: { title: 'TIN' },
    philhealth: { title: 'PhilHealth' },
    votersId: { title: "Voter's ID" },
    nationalId: {},
    postalId: {},
    barangayId: {},
  },
  {
    dbSchema: 'public',
  },
);

export const PersonId = T.IdNumber();
export const Person = PrismaModel(
  {
    name: 'Person',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['personalIdNumber'], ['phone']],
    description: 'Verified during KYC applicant data',
  },
  {
    id: T.AutoIncrement(PersonId),
    name: T.Nullable(T.String()),
    phone: T.FullPhone(),
    email: T.Nullable(T.Email()),
    personalIdNumber: T.Nullable(T.String({ title: 'Personal ID' })),
    phPersonalIdType: T.Nullable(PhPersonalIdType),
    dob: T.Nullable(T.LocalDate({ title: 'Date of birth' })),
    gender: T.Nullable(Gender),
    photo: T.Nullable(T.String()),
    photoId: T.Nullable(T.String()),
    kyc: T.Nullable(T.Boolean()),
  },
);

export const GeoDivisionType = T.OurEnum(
  {
    region: {},
    province: {},
    city: {},
    municipality: {},
    subMunicipality: {},
    barangay: {},
  },
  {
    dbSchema: 'public',
  },
);

export const GeoDivision = PrismaModel(
  {
    name: 'GeoDivision',
    dbSchema: 'public',
    key: ['code'],
    description: 'Philippines geographic area',
  },
  {
    code: T.String(),
    type: GeoDivisionType,
    name: T.String(),
    parentCode: T.Nullable(T.String()),
  },
);

export const RelGeoDivisionParent = PrismaRelation(
  GeoDivision,
  'parent',
  ['parentCode'],
  'Nullable',
  GeoDivision,
  'subdivisions',
  ['code'],
  'Array',
);

export const OnboardingStatus = T.OurEnum(
  {
    new: {},
    data_receaved: {}, // Legacy naming because of wide usage in analytics
  },
  {
    dbSchema: 'public',
    default: 'new',
  },
);

export const OnboardingQualification = T.OurEnum(
  {
    rejected: {},
    lowTicket: {},
    midTicket: {},
    highTicket: {},
  },
  {
    dbSchema: 'public',
  },
);

export const LegalType = T.OurEnum(
  {
    personal: {},
    corporate: {},
  },
  {
    dbSchema: 'public',
  },
);

export const SaleChannelType = T.OurEnum(
  {
    offline: {},
    online: {},
    onlineOffline: { title: 'Online&Offline' },
  },
  {
    dbSchema: 'public',
    title: 'Type of sales channel',
  },
);

export const PhBusinessCategory = T.OurEnum(
  {
    sariSariStore: {},
    petSuppliesStore: {},
    grocery: {},
    wholesaler: {},
    pharmacy: {},
    motorcycleShop: {},
    marketVendor: {},
    hardwareStore: {},
    foodBusiness: {},
    shopeeLazadaSeller: { title: 'Shopee/Lazada seller' },
    fbSeller: { title: 'FB seller' },
    onlineSeller: {},
    streetVendor: {},
    b2bSupplier: { title: 'B2B Supplier' },
    other: {},
  },
  {
    dbSchema: 'public',
    title: 'Phillipine business category',
  },
);

export const IdCity = T.OurEnum(
  {
    bali: {},
    bandarLampung: {},
    bandung: {},
    jabodetabek: {},
    malang: {},
    medan: {},
    palembang: {},
    semarang: {},
    surabaya: {},
    surakarta: {},
    yogyakarta: {},
    other: {},
  },
  {
    dbSchema: 'public',
  },
);
export const IdBusinessCategory = T.OurEnum(
  {
    agricultureFisheries: { title: 'Agriculture and Fisheries' },
    miningQuarrying: { title: 'Mining and Quarrying' },
    foodBeverageManufacturing: { title: 'Food and Beverage Manufacturing' },
    consumerGoodsManufacturing: { title: 'Consumer Goods Manufacturing' },
    energyUtilities: { title: 'Energy and Utilities' },
    wasteManagementEnvironmentalServices: {
      title: 'Waste Management and Environmental Services',
    },
    constructionInfrastructure: { title: 'Construction and Infrastructure' },
    wholesaleRetailTrade: { title: 'Wholesale and Retail Trade' },
    transportationLogistics: { title: 'Transportation and Logistics' },
    accommodationHospitality: { title: 'Accommodation and Hospitality' },
    restaurantsFoodServices: { title: 'Restaurants and Food Services' },
    informationTechnologySoftware: {
      title: 'Information Technology and Software',
    },
    financialServicesInsurance: { title: 'Financial Services and Insurance' },
    realEstatePropertyManagement: {
      title: 'Real Estate and Property Management',
    },
    professionalConsultancyServices: {
      title: 'Professional and Consultancy Services',
    },
    other: {},
  },
  {
    dbSchema: 'public',
  },
);

export const KycStatus = T.OurEnum(
  {
    success: {},
    failed: {},
    inProgress: {},
  },
  {
    dbSchema: 'public',
  },
);

export const NorthOrSouth = T.OurEnum({ north: {}, south: {} }, { dbSchema: 'public' });
export const SourceGroup = T.OurEnum({ online: {}, offline: {} }, { dbSchema: 'public' });

export const ApplicationId = T.HumanIdNumber();
export const Onboarding = PrismaModel(
  {
    name: 'Onboarding',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['leadOwnerId'], ['phone']],
    description: 'Application data filled by a client',
  },
  {
    id: T.AutoIncrement({ ...ApplicationId, title: 'ID' }),
    name: T.Nullable(T.String()),
    phone: T.Nullable(T.FullPhone()),
    email: T.Nullable(T.Email()),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt({ description: 'Date of last application change' }),
    submittedAt: T.CreatedAt({
      description: 'Date of onboarding finish. Ignore this value if status = new',
    }),
    status: OnboardingStatus,
    region: Region,
    utm: T.Nullable(
      T.Any({
        description: 'UTM tags from site (all values without utm_ prefixes)',
      }),
    ),
    ip: T.Nullable(T.IpAddress({ title: 'IP' })),
    userAgent: T.Nullable(T.String()),
    fingerprints: T.Array(T.String()),
    loanAmount: T.Nullable(T.Money({ title: 'Desired amount' })),
    revenue: T.Nullable(T.Money()),
    loanDuration: T.Nullable(T.Integer()),
    originalId: T.Nullable({
      ...ApplicationId,
      description: 'Link to original application if this one is a copy',
    }),
    loanOrderNumber: T.Integer({
      description: '> 1 if the client already had disbursed loans during onboarding',
      default: 1,
    }),
    shopName: T.Nullable(T.String()),
    geoDivisionCode: T.Nullable(T.String()),
    location: T.Nullable(T.String({ description: 'Business address' })),
    landmark: T.Nullable(T.String({ description: 'Location or address explication' })),
    geoPos: T.Array(T.Number()),
    phCheckingAccount: T.Nullable(
      T.Boolean({
        title: 'Does the client have checking account under his name ',
      }),
    ),
    phHasAfs: T.Nullable(T.Boolean({ title: 'Does the client have ITR/AFS for 2023?' })),
    phIsBusinessOwner: T.Nullable(T.Boolean({ title: 'Does client own the business' })),
    phIsReadyForVisit: T.Nullable(T.Boolean({ title: 'Is client ready for field visit' })),
    phCity: T.Nullable(PhCity),
    phBusinessCategory: T.Nullable(PhBusinessCategory),
    step: T.Nullable(
      T.String({
        description: 'Current step of the onboarding. Where the client stopped.',
      }),
    ),
    qualification: T.Nullable(OnboardingQualification),
    leadOwnerId: T.Nullable({ ...EmployeeId, description: 'Employee in charge of this application' }),
    applicantId: T.Nullable({ ...PersonId, description: 'Link to Person data verified during KYC' }),
    slackReportThreadId: T.Nullable(T.String()),
    idCity: T.Nullable(IdCity),
    idBusinessCategory: T.Nullable(IdBusinessCategory),
    loanPurpose: T.Nullable(T.String({ description: 'Indonesia only' })),
    legalType: T.Nullable(LegalType),
    legalCategory: T.Array(T.String(), { description: 'Indonesia only' }),
    typeOfSalesChannel: T.Nullable(SaleChannelType),
    yearsBusinessOperation: T.Nullable(
      T.Integer({
        title: 'How many years your business operates',
        description: 'Indonesia only',
      }),
    ),
    vidaKycStatus: T.Nullable(KycStatus),
    finfraUserId: T.Nullable(T.String({ description: 'Indonesia only' })),
    top5Suppliers: T.Nullable(
      T.Multiline({
        title: 'List of Top 5 Suppliers and Top 5 Customers',
        description: 'Indonesia only',
      }),
    ),
    outstandingDebt: T.Nullable(T.String({ description: 'Debt in previous loans\nIndonesia only' })),
    hubspotContactId: T.Nullable(T.String({ description: 'Trash' })),
    leadSource: T.Nullable(T.String({ description: 'Trash' })),
    hubspotLeadSource: T.String({
      title: 'Lead Source',
      description: 'Trash',
      default: '',
    }),
    hubspotTrafficSource: T.String({
      title: 'Traffic Source',
      description: 'Trash',
      default: '',
    }),
    shownName: T.Nullable(
      T.DbGeneratedString({
        description:
          'Name shown in BackOffice application\nEquals "name" column for lowTickets and "shopName" for others ',
      }),
    ),
    northOrSouth: Object.assign(T.Nullable(NorthOrSouth), {
      [PrismaTypeKind]: 'NorthOrSouth ? @default(dbgenerated())',
    }),
    sourceGroup: Object.assign(T.Nullable(SourceGroup), { [PrismaTypeKind]: 'SourceGroup ? @default(dbgenerated())' }),
  },
);
export const RelOnboardingOriginal = PrismaRelation(
  Onboarding,
  'original',
  ['originalId'],
  'Nullable',
  Onboarding,
  'copies',
  ['id'],
  'Array',
);

export const RelOnboardingApplicant = PrismaRelation(
  Onboarding,
  'applicant',
  ['applicantId'],
  'Nullable',
  Person,
  'onboardings',
  ['id'],
  'Array',
);
export const RelOnboardingLeadOwner = PrismaRelation(
  Onboarding,
  'leadOwner',
  ['leadOwnerId'],
  'Nullable',
  Employee,
  'ownedLeadOnboardings',
  ['id'],
  'Array',
);
export const RelOnboardingGeoDivision = PrismaRelation(
  Onboarding,
  'geoDivision',
  ['geoDivisionCode'],
  'Nullable',
  GeoDivision,
  'onboardings',
  ['code'],
  'Array',
);
export const OnboardingCompanyContact = PrismaModel(
  {
    name: 'OnboardingCompanyContact',
    dbSchema: 'public',
    key: ['onboardingId'],
    description: 'Indonesia only',
  },
  {
    onboardingId: ApplicationId,
    phone: T.Nullable(T.FullPhone({ title: 'Company phone' })),
    email: T.Nullable(T.Email({ title: 'Company email' })),
    finfraUserId: T.Nullable(T.String()),
  },
);
export const RelOnboardingCompanyContactOnboarding = PrismaRelation(
  OnboardingCompanyContact,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'companyContact',
  ['id'],
  'Nullable',
  'Cascade',
);

export const OnboardingEmergencyContact = PrismaModel(
  {
    name: 'OnboardingEmergencyContact',
    dbSchema: 'public',
    key: ['onboardingId'],
    description: 'Indonesia only',
  },
  {
    onboardingId: ApplicationId,
    name: T.Nullable(T.String()),
    phone: T.Nullable(T.FullPhone()),
    email: T.Nullable(T.Email()),
    relation: T.Nullable(T.String()),
  },
);
export const RelOnboardingEmergencyContactOnboarding = PrismaRelation(
  OnboardingEmergencyContact,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'emergencyContact',
  ['id'],
  'Nullable',
  'Cascade',
);

export const OnboardingCoownerId = T.IdNumber();
export const OnboardingCoowner = PrismaModel(
  {
    name: 'OnboardingCoowner',
    dbSchema: 'public',
    key: ['id'],
    description: 'Business coowner information\nIndonesia only',
  },
  {
    id: T.AutoIncrement(OnboardingCoownerId),
    onboardingId: ApplicationId,
    name: T.Nullable(T.String()),
    role: T.Nullable(T.String()),
    phone: T.Nullable(T.FullPhone()),
    email: T.Nullable(T.Email()),
    ktp: T.Nullable(T.String({ title: 'NIK' })),
    shareholding: T.Nullable(T.Percent()),
    finfraUserId: T.Nullable(T.String()),
  },
);

export const RelOnboardingCoownerOnboarding = PrismaRelation(
  OnboardingCoowner,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'coowners',
  ['id'],
  'Array',
  'Cascade',
);

export const ShopType = T.OurEnum(
  {
    shopee: {},
    tokopedia: {},
    lazada: {},
    tiktok: {},
    blibli: {},
    website: {},
    youtube: {},
    instagram: {},
    facebook: {},
    twitter: { title: 'X (Twitter)' },
  },
  {
    dbSchema: 'public',
  },
);
export const OnboardingSaleChannelId = T.IdNumber();
export const OnboardingSaleChannel = PrismaModel(
  {
    name: 'OnboardingSaleChannel',
    dbSchema: 'public',
    key: ['id'],
    indexes: [['onboardingId']],
    description: 'Link to a marketplace or website',
  },
  {
    id: T.AutoIncrement(OnboardingSaleChannelId),
    onboardingId: ApplicationId,
    type: ShopType,
    name: T.Nullable(T.String()),
    link: T.Uri(),
    isSynced: T.Boolean({ default: false }),
    isDataLoaded: T.Boolean({ default: false }),
  },
);

export const RelOnboardingSaleChannelOnboarding = PrismaRelation(
  OnboardingSaleChannel,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'saleChannels',
  ['id'],
  'Array',
  'Cascade',
);

export const PersonUnverifiedData = PrismaModel(
  {
    name: 'PersonUnverifiedData',
    dbSchema: 'public',
    key: ['onboardingId'],
    description: "Applicant's information submitted during onboarding and not passed KYC",
  },
  {
    onboardingId: ApplicationId,
    fullName: T.String({ title: 'Full name' }),
    personalIdNumber: T.String({ title: 'Personal ID' }),
    phPersonalIdType: T.Nullable(PhPersonalIdType),
    dob: T.LocalDate({ title: 'Birth Date' }),
    selfiePhotoIds: T.Array(T.String()),
    gender: T.Nullable(Gender),
    selfieFaceScores: T.Array(T.IntPercent()),
    selfieBusinessScores: T.Array(T.IntPercent()),
  },
);
export const RelPersonUnverifiedDataOnboarding = PrismaRelation(
  PersonUnverifiedData,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'personUnverifiedData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const OnboardingLogin = PrismaModel(
  {
    name: 'OnboardingLogin',
    dbSchema: 'public',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    onboardingId: ApplicationId,
    date: T.CreatedAt(),
    utm: T.Nullable(
      T.Any({
        description: 'UTM tags from site (all values without utm_ prefixes)',
      }),
    ),
    ip: T.Nullable(T.IpAddress({ title: 'IP' })),
    userAgent: T.Nullable(T.String()),
    fingerprint: T.Nullable(T.String()),
    facebookBrowserId: T.Nullable(T.String()),
    facebookClickId: T.Nullable(T.String()),
    googleAnalyticsClientId: T.Nullable(T.String()),
    googleAnalyticsSessionId: T.Nullable(T.String()),
  },
);
export const RelOnboardingLoginOnboarding = PrismaRelation(
  OnboardingLogin,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'logins',
  ['id'],
  'Array',
  'Cascade',
);
