import type { StringDigit } from 'type-fest/source/internal';

type ValidateSimpleMask<T extends string> = T extends ''
  ? true
  : T extends `${infer _ extends StringDigit | '-' | '+' | '?' | ' '}${infer R extends string}`
    ? ValidateSimpleMask<R>
    : false;

export const phone = {
  format: (value: string) => {
    const phoneNums = value.replace(/^0+|\D/g, '');

    const areaCode = `+${phoneNums.slice(0, 2)}`;
    const firstPart = phoneNums.slice(2, 5);
    const middlePart = phoneNums.slice(5, 9);
    const lastPart = phoneNums.slice(9, 14);

    if (!lastPart) {
      return `${areaCode} ${firstPart} ${middlePart}`.trim();
    }

    return `${areaCode} ${firstPart} ${middlePart}-${lastPart}`;
  },

  extractMaskedPhone: <const T extends string>(
    mask: T,
    _number: ValidateSimpleMask<T> extends true ? string : never,
  ) => {
    const number = (_number as string).replace(/[\s()-]/g, '');

    let result = '';
    let i = 0;
    let j = 0;
    for (; i < mask.length && j < number.length; i++) {
      if (/^[\s()-]$/.test(mask[i] ?? '')) {
        result += mask[i];
      } else if (mask[i] === '9') {
        if (!Number.isNaN(Number(number[j]))) {
          result += number[j];
          j++;
        } else {
          break;
        }
      } else {
        if (mask[i] === number[j]) {
          j++;
        }
        result += mask[i];
      }
    }
    return result;
  },
};
