import { DF } from '../../vendor/date-fns';
import type { FormatComponent, InputtableSchema, RichTypeOpts, SimpleFormattable } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  VueComponentKind,
  VueInputComponentKind,
  VueTableCellComponentKind,
} from '../base';
import { Format } from '../formats';
import * as Type from '../vendorType';
import { tz } from '@getmo/common/vendor/@date-fns/tz';
import type { DatePickerProps } from 'primevue/datepicker';
import { h } from 'vue';

export const dateFormat = Intl.DateTimeFormat(undefined, { day: '2-digit', month: 'short', year: 'numeric' });
export const dateTimeFormat = Intl.DateTimeFormat('en-US', {
  hourCycle: 'h23',
  dateStyle: 'medium',
  timeStyle: 'short',
});

const createFormatComponent = <T extends Type.TSchema>(format: string) =>
  createAsyncComponentWrapper(
    () => import('../../components/DateFormat.vue'),
    (Component) =>
      ({ value }) =>
        h(Component, { date: value, format }),
  ) satisfies FormatComponent<T>;
export interface TDate
  extends SimpleFormattable,
    Type.TTransform<Type.TString, Date>,
    InputtableSchema<DatePickerProps> {
  format: Format.DateTime;
}
Type.FormatRegistry.Set(Format.DateTime, () => true);
export const IsDate = (schema: unknown): schema is TDate =>
  Type.IsTransform(schema) && Type.IsString(schema) && schema.format === Format.DateTime;
const dateRichOpts = {
  [FormatFuncKind]: (value) => dateTimeFormat.format(value),
  [VueComponentKind]: createFormatComponent('PPpp'),
  [VueTableCellComponentKind]: createAsyncComponentWrapper(
    () => import('./DateTableCell.vue'),
    (C) => C,
  ),
  [PrismaTypeKind]: 'DateTime @db.Timestamptz',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/datepicker'),
    (C) => (props) =>
      h(C, {
        inputId: props.id,
        dateFormat: 'dd-mm-yy',
        placeholder: 'dd-mm-yyyy',
        ...props,
        id: `${props.id}_wrapper`,
      }),
  ),
} satisfies RichTypeOpts<TDate>;
const DateType = (opts?: Type.SchemaOptions): TDate =>
  createType(
    Type.Transform(Type.String({ format: Format.DateTime, ...opts }))
      .Decode((v) => {
        const d = new Date(v);
        if (!DF.isValid(d)) throw new Error('Invalid date');
        return d;
      })
      .Encode((v) => v.toISOString()),
    dateRichOpts,
  );
export { DateType as Date };

export interface TLocalDate
  extends SimpleFormattable,
    Type.TTransform<Type.TString, Date>,
    InputtableSchema<DatePickerProps> {
  format: Format.Date;
}
Type.FormatRegistry.Set(Format.Date, () => true);
export const IsLocalDate = (schema: unknown): schema is TLocalDate =>
  Type.IsTransform(schema) && Type.IsString(schema) && schema.format === Format.Date;
const localDateRichOpts = {
  [FormatFuncKind]: (value) => dateFormat.format(value),
  [VueComponentKind]: createFormatComponent('PP'),
  [PrismaTypeKind]: 'DateTime @db.Date /// [LocalDate]',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/datepicker'),
    (C) => (props) =>
      h(C, {
        inputId: props.id,
        dateFormat: 'dd-mm-yy',
        placeholder: 'dd-mm-yyyy',
        ...props,
        id: `${props.id}_wrapper`,
      }),
  ),
} satisfies RichTypeOpts<TLocalDate>;
export const LocalDate = (opts?: Type.SchemaOptions): TLocalDate =>
  createType(
    Type.Transform(Type.String({ format: Format.Date, ...opts }))
      .Decode((v) => {
        const d = DF.parse(v, 'yyyy-MM-dd', 0);
        return d;
      })
      .Encode((v) => DF.format(v, 'yyyy-MM-dd')),
    localDateRichOpts,
  );

export interface TLocalDateTime extends SimpleFormattable, Type.TTransform<Type.TString, Date> {
  format: Format.LocalDateTime;
}
Type.FormatRegistry.Set(Format.LocalDateTime, () => true);
export const IsLocalDateTime = (schema: unknown): schema is TLocalDate =>
  Type.IsTransform(schema) && Type.IsString(schema) && schema.format === Format.LocalDateTime;
const localDateTimeRichOpts = {
  [FormatFuncKind]: (value) => dateTimeFormat.format(value),
  [VueComponentKind]: createFormatComponent('PPpp'),
  [VueTableCellComponentKind]: createAsyncComponentWrapper(
    () => import('./DateTableCell.vue'),
    (C) => C,
  ),
} satisfies RichTypeOpts<TLocalDateTime>;
export const LocalDateTime = (opts?: Type.SchemaOptions): TLocalDateTime =>
  createType(
    Type.Transform(Type.String({ format: Format.LocalDateTime, ...opts }))
      .Decode((v) => {
        const d = new Date(v);
        if (!DF.isValid(d)) throw new Error('Invalid date');
        return d;
      })
      .Encode((v) => DF.format(v, 'yyyy-MM-dd HH:mm:ss')),
    localDateTimeRichOpts,
  );

export const CreatedAt = (opts?: Type.SchemaOptions): TDate =>
  createType(DateType(opts), { [PrismaTypeKind]: 'DateTime @default(now()) @db.Timestamptz' });
export const UpdatedAt = (opts?: Type.SchemaOptions): TDate =>
  createType(DateType(opts), { [PrismaTypeKind]: 'DateTime @updatedAt @db.Timestamptz' });

export interface TFixedZoneDateTime extends SimpleFormattable, Type.TTransform<Type.TString, Date> {
  format: Format.FixedZoneDateTime;
}
Type.FormatRegistry.Set(Format.FixedZoneDateTime, () => true);
export const IsFixedZoneDateTime = (schema: unknown): schema is TLocalDate =>
  Type.IsTransform(schema) && Type.IsString(schema) && schema.format === Format.FixedZoneDateTime;
const fixedZoneDateTimeRichOpts = {
  [FormatFuncKind]: (value) => dateTimeFormat.format(value),
  [VueComponentKind]: createFormatComponent('PP'),
} satisfies RichTypeOpts<TFixedZoneDateTime>;
export const FixedZoneDateTime = (timeZone: string, opts?: Type.SchemaOptions): TFixedZoneDateTime =>
  createType(
    Type.Transform(Type.String({ format: Format.FixedZoneDateTime, ...opts }))
      .Decode((v) => new Date(DF.parseISO(v, { in: tz(timeZone) })))
      .Encode((v) => DF.format(v, 'yyyy-MM-dd HH:mm:ss', { in: tz(timeZone) })),
    fixedZoneDateTimeRichOpts,
  );
