import { isPrismaType, PrismaTypeKind } from '../base';
import type * as Type from '../vendorType';

const UniqueKind = Symbol.for('UniqueKind');

export type TUnique<T extends Type.TSchema> = T & { [UniqueKind]: true };

export const Unique = <T extends Type.TSchema>(schema: T): TUnique<T> => ({
  ...schema,
  [UniqueKind]: true,
  [PrismaTypeKind]: isPrismaType(schema) ? schema[PrismaTypeKind] + ' @unique' : undefined,
});
