import type { InputComponent } from '../base';
import { defineFC, isInputtable, VueInputComponentKind } from '../base';
import * as Type from '@sinclair/typebox';
import { h } from 'vue';

export const { IsOptional } = Type.TypeGuard;

const OrigVueInputComponentKind = Symbol.for('OrigVueInputComponentKind');

const VueInputComponent: InputComponent<Type.TSchema, object> = defineFC(
  (props, { emit }) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    h((props.schema as any)[OrigVueInputComponentKind], {
      ...props,
      nullable: true,
      modelValue: props.modelValue ?? null,
      'onUpdate:modelValue': (val: unknown) => emit('update:modelValue', val ?? undefined),
    }),
  { emits: ['update:modelValue'] },
);

export const Optional = <T extends Type.TSchema>(schema: T): Type.TOptional<T> =>
  ({
    ...Type.Optional(schema),
    ...(isInputtable(schema) && {
      [OrigVueInputComponentKind]: schema[VueInputComponentKind],
      [VueInputComponentKind]: VueInputComponent,
    }),
  }) as never;
