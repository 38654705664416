import { Currency } from './common';
import { Loan } from './loans';
import { ApplicationId, Onboarding } from './onboarding';
import { Invoice } from './payments';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';

export const PaymentStatus = T.OurEnum(
  {
    success: {},
    failure: {},
    pending: {},
  },
  {
    dbSchema: 'backoffice',
  },
);

export const XenditCustomer = PrismaModel(
  {
    name: 'XenditCustomer',
    dbSchema: 'public',
    key: ['externalId'],
  },
  {
    externalId: T.String(),
    linkedAccountTokenId: T.Nullable(T.String()),
    paymentMethodId: T.Nullable(T.String()),
    paymentMethodDisplay: T.Nullable(T.String()),
    onboardingId: T.Unique(ApplicationId),
  },
);
export const RelXenditCustomerOnboarding = PrismaRelation(
  XenditCustomer,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'xenditCustomer',
  ['id'],
  'Nullable',
);

export const XenditPayment = PrismaModel(
  {
    name: 'XenditPayment',
    dbSchema: 'backoffice',
    key: ['referenceId'],
  },
  {
    referenceId: T.String(),
    externalId: T.Unique(T.Nullable(T.String())),
    paymentMethodId: T.String(),
    paymentMethodDisplay: T.Nullable(T.String()),
    currency: Currency,
    amount: T.Money(),
    status: PaymentStatus,
    failureCode: T.Nullable(T.String()),
    webhookIds: T.Array(T.String()),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    loanId: ApplicationId,
    xenditCustomerId: T.Nullable(T.String()),
    invoiceDate: T.LocalDate(),
  },
);
export const RelXenditPaymentXenditCustomer = PrismaRelation(
  XenditPayment,
  'xenditCustomer',
  ['xenditCustomerId'],
  'Nullable',
  XenditCustomer,
  'xenditPayments',
  ['externalId'],
  'Array',
  'SetNull',
);

export const RelXenditPaymentLoan = PrismaRelation(
  XenditPayment,
  'loan',
  ['loanId'],
  '',
  Loan,
  'xenditPayments',
  ['id'],
  'Array',
  'Restrict',
);

export const AyoconnectCard = PrismaModel(
  {
    name: 'AyoconnectCard',
    dbSchema: 'public',
    key: ['referenceNumber'],
  },
  {
    referenceNumber: T.String(),
    authCode: T.String(),
    publicUserId: T.Nullable(T.String()),
    maskedCard: T.Nullable(T.String()),
    accountToken: T.Nullable(T.String()),
    bankCode: T.Nullable(T.String()),
    onboardingId: T.Unique(ApplicationId),
  },
);
export const RelAyoconnectCardOnboarding = PrismaRelation(
  AyoconnectCard,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'ayoconnectCards',
  ['id'],
  'Array',
  'Cascade',
);

export const AyoconnectPayment = PrismaModel(
  {
    name: 'AyoconnectPayment',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    amount: T.Money(),
    status: PaymentStatus,
    invoiceDate: T.LocalDate(),
    flowId: T.Unique(T.Nullable(T.String())),
    failureReason: T.Nullable(T.String()),
    maskedCard: T.Nullable(T.String()),
    loanId: ApplicationId,
    cardReferenceNumber: T.Nullable(T.String()),
  },
);
export const RelAyoconnectPaymentCard = PrismaRelation(
  AyoconnectPayment,
  'card',
  ['cardReferenceNumber'],
  'Nullable',
  AyoconnectCard,
  'ayoconnectPayments',
  ['referenceNumber'],
  'Array',
  'SetNull',
);
export const RelAyoconnectPaymentLoan = PrismaRelation(
  AyoconnectPayment,
  'loan',
  ['loanId'],
  '',
  Loan,
  'ayoconnectPayments',
  ['id'],
  'Array',
  'Restrict',
);

export const RelAyoconnectPaymentInvoice = PrismaRelation(
  AyoconnectPayment,
  'invoice',
  ['loanId', 'invoiceDate'],
  '',
  Invoice,
  'ayoconnectPayments',
  ['loanId', 'date'],
  'Array',
  'Restrict',
);
export const RelXenditPaymentInvoice = PrismaRelation(
  XenditPayment,
  'invoice',
  ['loanId', 'invoiceDate'],
  '',
  Invoice,
  'xenditPayments',
  ['loanId', 'date'],
  'Array',
  'Restrict',
);
