import { R } from '../../vendor/remeda';
import { createType } from '../base';
import { enumRichOpts } from '../vendorType';
import * as Type from '../vendorType';

export type TOurEnum<T extends Type.TEnumValue = Type.TEnumValue> = Type.TEnum<Record<T, T>>;

export const IsEnum = (schema: unknown): schema is TOurEnum => Type.IsUnion(schema) && schema[Type.Hint] === 'Enum';
export const OurEnum = <const T extends Type.TEnumValue>(
  values: Record<T, Type.SchemaOptions>,
  opts?: Type.SchemaOptions,
): TOurEnum<T> =>
  createType(
    {
      ...Type.Union(
        R.map(R.entries(values) as never, ([k, v]: [T, Type.SchemaOptions]) => Type.Literal(k, v)),
        opts,
      ),
      [Type.Hint]: 'Enum',
    } as never,
    enumRichOpts,
  );
export const OurEnumNumeric = <const T extends Type.TEnumValue>(
  values: [T, Type.SchemaOptions][],
  opts?: Type.SchemaOptions,
): TOurEnum<T> =>
  createType(
    {
      ...Type.Union(
        R.map(values, ([k, v]) => Type.Literal(k, v)),
        opts,
      ),
      [Type.Hint]: 'Enum',
    } as never,
    enumRichOpts,
  );
