import * as Type from '../vendorType';

const customTypeKind = 'Custom';
const CustomPredicate = 'Custom predicate';
export interface CustomType<T> extends Type.TSchema {
  [Type.Kind]: typeof customTypeKind;
  [CustomPredicate]: (v: unknown) => boolean;
  static: T;
}
export const CustomType = <T>(base: Type.TSchema, predicate: (v: unknown) => boolean): CustomType<T> =>
  ({
    ...base,
    [Type.Kind]: customTypeKind,
    [CustomPredicate]: predicate,
  }) as never;
Type.TypeRegistry.Set<CustomType<Type.TSchema>>(customTypeKind, (schema, value) => schema[CustomPredicate](value));
