import { PrismaModel, PrismaRelation, T } from '../../../typebox';
import { createAsyncComponentWrapper, VueComponentKind, withRichOpts } from '../../../typebox/base';
import { Employee, EmployeeId, UserRole } from '../auth';
import { UnderwritingPipeline } from '../common';
import { ApplicationId, Onboarding } from '../onboarding';
import { NotRichUnderwritingStatus } from './notRich';

export const SalesAgentId = T.IdNumber();
export const SalesAgent = PrismaModel(
  {
    name: 'SalesAgent',
    dbSchema: 'public',
    key: ['id'],
  },
  {
    id: T.AutoIncrement(SalesAgentId),
    name: T.String(),
  },
);

export const UnderwritingStatus = withRichOpts(NotRichUnderwritingStatus, {
  [VueComponentKind]: createAsyncComponentWrapper(
    () => import('../../../components/UnderwritingStatus.vue') as never,
    (C) => C,
  ),
});

export const TermsSuggestionId = T.IdNumber();

export const Underwriting = PrismaModel(
  {
    name: 'Underwriting',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['selectedTermsId']],
    description: "State of application's underwriting process",
  },
  {
    id: { ...ApplicationId, title: 'ID' },
    salesAgentId: T.Nullable(SalesAgentId),
    createdAt: T.CreatedAt({ title: 'Request date' }),
    updatedAt: T.UpdatedAt(),
    visitedAt: T.Nullable(T.Date({ description: 'Date and time of Field visit' })),
    order: T.Unique(T.AutoIncrement(T.IdNumber())),
    pipeline: UnderwritingPipeline,
    status: UnderwritingStatus,
    fieldAgentId: T.Nullable(EmployeeId),
    underwriterId: T.Nullable(EmployeeId),
    selectedTermsId: T.Nullable(TermsSuggestionId),
    isInAutoFieldVisitAbTest: T.Boolean({ default: false }),
  },
);

export const RelUnderwritingOnboarding = PrismaRelation(
  Underwriting,
  'onboarding',
  ['id'],
  '',
  Onboarding,
  'underwriting',
  ['id'],
  'Nullable',
);

export const RelUnderwritingSalesAgent = PrismaRelation(
  Underwriting,
  'salesAgent',
  ['salesAgentId'],
  'Nullable',
  SalesAgent,
  'underwritings',
  ['id'],
  'Array',
);
export const RelUnderwritingFieldAgent = PrismaRelation(
  Underwriting,
  'fieldAgent',
  ['fieldAgentId'],
  'Nullable',
  Employee,
  'fieldVisits',
  ['id'],
  'Array',
);
export const RelUnderwritingUnderwriter = PrismaRelation(
  Underwriting,
  'underwriter',
  ['underwriterId'],
  'Nullable',
  Employee,
  'underwritings',
  ['id'],
  'Array',
);

export const ProductType = T.OurEnum(
  {
    fixedInterest: {},
    rbf: {},
    bullet: {},
    balloon: {},
    archivedIndonesian: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const PaymentFrequency = T.OurEnum(
  {
    weekly: {},
    biWeekly: {},
    monthly: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const TermsSuggestion = PrismaModel(
  {
    name: 'TermsSuggestion',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['underwritingId'], ['employeeId']],
    description: 'Terms suggested (might be several for each application)',
  },
  {
    id: T.AutoIncrement(TermsSuggestionId),
    underwritingId: ApplicationId,
    date: T.CreatedAt(),
    productType: ProductType,
    rate: T.Percent(),
    amount: T.Money(),
    term: T.Integer(),
    originationFee: T.Percent(),
    paymentFrequency: PaymentFrequency,
    holdback: T.Percent(),
    minPayment: T.Money(),
    comment: T.Multiline({ title: 'Pre-disbursement conditions' }),
    nplRate: T.Percent({ title: 'NPL rate', default: 5 }),
    pdcAccount: T.Nullable(T.String({ title: 'Account for PDC' })),
    employeeId: EmployeeId,
  },
);

export const RelTermsSuggestionUnderwriting = PrismaRelation(
  TermsSuggestion,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'termsSuggestions',
  ['id'],
  'Array',
  'Cascade',
);
export const RelUnderwritingSelectedTerms = PrismaRelation(
  Underwriting,
  'selectedTerms',
  ['selectedTermsId'],
  'Nullable',
  TermsSuggestion,
  'selectingUnderwritings',
  ['id'],
  'Array',
  'SetNull',
);
export const RelTermsSuggestionEmployee = PrismaRelation(
  TermsSuggestion,
  'employee',
  ['employeeId'],
  '',
  Employee,
  'termsSuggestions',
  ['id'],
  'Array',
);

export const UnderwritingNegative = T.OurEnum(
  {
    // All tickets
    amount: { title: 'Requested amount' },
    creditHistory: { title: 'Credit history is negative' },
    weakFinancialPosition: { title: 'Weak financial position' },
    misleadLender: { title: 'Tried to mislead the lender' },

    // Low tickets
    businessNotFound: { title: 'Business not found / Business no longer operational' },
    lowInventory: {},
    rarelyOpened: { title: 'Store is rarely opened / Business owner is absent' },
    notOwner: { title: 'Store does not belong to the applicant' },
    riskyArea: {},
    noBusinessPermit: { title: 'Not willing to provide business permit' },
    borrowerInGovernment: { title: 'Borrower is a government official/employee' },
    relativeInGovernment: { title: 'Relative works in government' },
    lowScore: {},

    // Qualification
    businessCategory: {},
    outOfOperationalArea: { title: 'Location is out of operational area' },
    revenue: {},
    notReadyForVisit: {},
    age: { title: "Client's age" },

    // Common
    cancelledHighInterest: { title: 'Cancelled - High interest' },
    cancelledHighDeduction: { title: 'Cancelled - High deduction' },
    cancelledLowAmount: { title: 'Cancelled - Low amount' },
    cancelledWeeklyPayments: { title: 'Client cannot make weekly payments' },
    cancelledTesting: { title: 'Cancelled - Client just tried the website' },
    cancelledOther: { title: 'Cancelled - Other reasons' },
    productCriteria: { title: 'Does not meet the product criteria' },
    other: {},
  },
  { dbSchema: 'backoffice' },
);
export const UnderwritingAction = PrismaModel(
  {
    name: 'UnderwritingAction',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['selectedTermsId'], ['underwritingId'], ['employeeId']],
    description:
      'Backoffice statuses and comments. All steps passed by each application. The very first stage "new" is not included.\nIf an application was declined and then got back into the process, "new" stage will be included.',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    underwritingId: ApplicationId,
    date: T.CreatedAt({
      description: 'Date and time when the application got the status',
    }),
    negatives: T.Array(UnderwritingNegative),
    comment: T.RichText(),
    newStatus: T.Nullable(UnderwritingStatus),
    isAutoCheck: T.Boolean({ default: false }),
    isLocationVisited: T.Nullable(T.Boolean()),
    employeeId: { ...EmployeeId, description: 'Employee who transfered the application into the status' },
    employeeRole: UserRole,
    selectedTermsId: T.Nullable({ ...TermsSuggestionId, title: 'Terms suggested at the stage' }),
  },
);
export const RelUnderwritingActionUnderwriting = PrismaRelation(
  UnderwritingAction,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'actions',
  ['id'],
  'Array',
  'Cascade',
);
export const RelUnderwritingActionEmployee = PrismaRelation(
  UnderwritingAction,
  'employee',
  ['employeeId'],
  '',
  Employee,
  'underwritingActions',
  ['id'],
  'Array',
);
export const RelUnderwritingActionSelectedTerms = PrismaRelation(
  UnderwritingAction,
  'selectedTerms',
  ['selectedTermsId'],
  'Nullable',
  TermsSuggestion,
  'selectingUnderwritingActions',
  ['id'],
  'Array',
  'SetNull',
);

export const JointeffPaymentHistoryId = T.IdNumber();
export const JointeffPaymentHistory = PrismaModel(
  {
    name: 'JointeffPaymentHistory',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Jointeff response data',
  },
  {
    id: T.AutoIncrement(JointeffPaymentHistoryId),
    underwritingId: T.Unique(ApplicationId),
    token: T.Nullable(T.String()),
  },
);
export const JointeffPayment = PrismaModel(
  {
    name: 'JointeffPayment',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Description of a payment from Jointeff response',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    index: T.Integer(),
    refDate: T.Date(),
    merchantId: T.String(),
    amount: T.Money(),
    currency: T.String(),
    description: T.String(),
    procId: T.String(),
    category: T.String(),
    paymentHistoryId: JointeffPaymentHistoryId,
  },
);
export const RelJointeffPaymentPaymentHistory = PrismaRelation(
  JointeffPayment,
  'paymentHistory',
  ['paymentHistoryId'],
  '',
  JointeffPaymentHistory,
  'payments',
  ['id'],
  'Array',
  'Cascade',
);
export const RelJointeffPaymentHistoryUnderwriting = PrismaRelation(
  JointeffPaymentHistory,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'jointeffPaymentHistory',
  ['id'],
  'Nullable',
  'Cascade',
);

export const FieldVisitTenure = T.OurEnum(
  {
    lessThanYear: {},
    y1: { title: '1' },
    y2: { title: '2' },
    y3: { title: '3' },
    y4: { title: '4' },
    y5: { title: '5' },
    y6: { title: '6' },
    y7: { title: '7' },
    y8: { title: '8' },
    y9: { title: '9' },
    y10: { title: '10' },
    moreThan10Years: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const FieldVisitReputation = T.OurEnum(
  {
    positive: {},
    negative: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const FieldVisitHouseOwnership = T.OurEnum(
  {
    own: {},
    rent: {},
    freeUse: {},
    is: { title: 'IS' },
  },
  {
    dbSchema: 'backoffice',
  },
);
export const FieldVisitBusinessPermit = T.OurEnum(
  {
    yes: {},
    no: {},
    bc: { title: 'BC' },
  },
  {
    dbSchema: 'backoffice',
  },
);
export const FieldVisitEyeTest = T.OurEnum(
  {
    good: {},
    med: {},
    bad: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const FieldVisitGross = T.OurEnum(
  {
    g2kAndBelow: { title: '2K and below' },
    g3kTo5k: { title: '2.1K to 6K' },
    g6kTo10k: { title: '6K to 10K' },
    g10kAndUp: { title: '10K and up' },
  },
  {
    dbSchema: 'backoffice',
  },
);
export const UnderwritingPhFieldVisitData = PrismaModel(
  {
    name: 'UnderwritingPhFieldVisitData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Credit investigation field visit data',
  },
  {
    underwritingId: ApplicationId,
    geoPos: T.Array(T.Number(), { description: 'Visit geoposition' }),
    isLivingInAddress: T.Nullable(T.Boolean({ title: 'Living in the given address' })),
    communityTenure: T.Nullable(FieldVisitTenure),
    barangayReputation: T.Nullable(FieldVisitReputation),
    hasRelativesInGovermentByBarangay: T.Nullable(T.Boolean({ title: 'Relative in office or the force' })),
    hasCollectorVisits: T.Nullable(T.Boolean({ title: 'Do other collectors visit the borrower' })),
    isAlwaysOpen: T.Nullable(T.Boolean({ title: 'Is the business always open' })),
    communityReputation: T.Nullable(FieldVisitReputation),
    hasRelativesInGovermentByNeighbor: T.Nullable(T.Boolean({ title: 'Relative in office or the force' })),
    houseOwnership: T.Nullable(FieldVisitHouseOwnership),
    businessPermit: T.Nullable(FieldVisitBusinessPermit),
    businessTenure: T.Nullable(FieldVisitTenure),
    eyeTest: T.Nullable(FieldVisitEyeTest),
    dailyGross: T.Nullable(FieldVisitGross),
  },
);

export const RelUnderwritingPhFieldVisitDataUnderwriting = PrismaRelation(
  UnderwritingPhFieldVisitData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'phFieldVisitData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingNote = PrismaModel(
  {
    name: 'UnderwritingNote',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['underwritingId'], ['employeeId']],
    description: "Additional comment for an underwriting form's value",
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    underwritingId: ApplicationId,
    key: T.String(),
    date: T.CreatedAt(),
    text: T.Multiline(),
    employeeId: EmployeeId,
  },
);
export const RelUnderwritingNoteUnderwriting = PrismaRelation(
  UnderwritingNote,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'notes',
  ['id'],
  'Array',
  'Cascade',
);
export const RelUnderwritingNoteEmployee = PrismaRelation(
  UnderwritingNote,
  'employee',
  ['employeeId'],
  '',
  Employee,
  'underwritingNotes',
  ['id'],
  'Array',
);

export const DisbursementState = PrismaModel(
  {
    name: 'DisbursementState',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Pre-disbursement process state',
  },
  {
    underwritingId: ApplicationId,
    destAccountType: T.String({ default: '' }),
    destAccountNumber: T.String({ default: '' }),
    plannedDate: T.LocalDate(),
    slackThreadId: T.Nullable(T.String()),
    loanAgreementNumber: T.Unique(T.Nullable(T.String())),
    signature: T.Nullable(T.String()),
    disbursementRequested: T.Boolean({ default: false }),
  },
);

export const RelDisbursementStateUnderwriting = PrismaRelation(
  DisbursementState,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'disbursementState',
  ['id'],
  'Nullable',
  'Cascade',
);
