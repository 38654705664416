import { R } from '../../vendor/remeda';
import type {
  FormatComponent,
  FormatOptions,
  FormattableSchema,
  IfDefined,
  InputComponent,
  InputtableSchema,
  MaybeObjectFormatOptions,
  RichTypeOpts,
} from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  format,
  FormatFuncKind,
  isFormattable,
  isInputtable,
  isPrismaType,
  PrismaTypeKind,
  VueComponentKind,
  VueInputComponentKind,
  VueTableCellComponentKind,
} from '../base';
import * as Type from '../vendorType';
import { h } from 'vue';

export interface TNullable<T extends Type.TSchema>
  extends Type.TUnion<[Type.TNull, T]>,
    FormattableSchema<
      MaybeObjectFormatOptions<
        IfDefined<FormatOptions<T>> & {
          nullableFallback?: string;
        }
      >
    >,
    InputtableSchema<T extends InputtableSchema<infer P> ? P : never> {}

const richOpts = {
  [FormatFuncKind]: (value, opts, schema) =>
    value === null
      ? (opts?.nullableFallback ?? 'N/A')
      : format(unwrapNullable(schema) as never, value as never, opts as never),
  [VueComponentKind]: createAsyncComponentWrapper(
    () => import('../Format.vue'),
    (Format): FormatComponent<TNullable<Type.TSchema>> =>
      ({ value, schema: nullableSchema, opts }) =>
        value === null
          ? (opts?.nullableFallback ?? 'N/A')
          : h(Format, { value, opts, schema: unwrapNullable(nullableSchema) }),
  ),
  [VueTableCellComponentKind]: createAsyncComponentWrapper(
    () => import('../TableCellBy'),
    (C): FormatComponent<TNullable<Type.TSchema>> =>
      ({ value, schema: nullableSchema, opts }) =>
        value === null
          ? (opts?.nullableFallback ?? 'N/A')
          : h(C, { value, opts, schema: unwrapNullable(nullableSchema) }),
  ),
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('../InputBy'),
    (C) =>
      ((props) => {
        const schema = unwrapNullable(props.schema);
        return h(C, { ...props, schema, nullable: true });
      }) as InputComponent<TNullable<Type.TSchema>, object>,
  ),
} as RichTypeOpts<TNullable<Type.TSchema>>;

export const Nullable = <T extends Type.TSchema>(schema: T, o?: Type.SchemaOptions): TNullable<T> =>
  createType(
    Type.Union([Type.Null(), schema], {
      ...R.pick(schema, ['title', 'description']),
      default: null,
      ...o,
    }),
    {
      [PrismaTypeKind]: isPrismaType(schema)
        ? R.splice(schema[PrismaTypeKind].split(' '), 1, 0, ['?']).join(' ')
        : undefined,
      ...richOpts,
      ...(!isFormattable(schema) && {
        [FormatFuncKind]: undefined,
        [VueComponentKind]: undefined,
        [VueTableCellComponentKind]: undefined,
      }),
      ...(!isInputtable(schema) && {
        [VueInputComponentKind]: undefined,
      }),
    } as never,
  );

export const IsNullable = (schema: unknown): schema is TNullable<Type.TSchema> =>
  Type.IsUnion(schema) && schema.anyOf.length === 2 && schema.anyOf.some((s) => Type.IsNull(s));

export const createNullableGuard =
  <T extends Type.TSchema>(predicate: (s: unknown) => s is T) =>
  (v: unknown): v is T | TNullable<T> =>
    predicate(v) || (Type.IsUnion(v) && v.anyOf.every((s) => Type.IsNull(s) || predicate(s)));

export type UnwrapNullable<T extends Type.TSchema> = T extends TNullable<infer S> ? S : T;
export const unwrapNullable = <T extends Type.TSchema>(schema: T) =>
  (IsNullable(schema) ? schema.anyOf.find((s) => !Type.IsNull(s)) : schema) as UnwrapNullable<T>;
