import { Employee, EmployeeId } from './auth';
import { ApplicationId } from './onboarding';
import { FieldVisitEyeTest, TermsSuggestion, TermsSuggestionId, Underwriting } from './underwriting';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';

export const VirtualAccount = PrismaModel(
  {
    name: 'VirtualAccount',
    dbSchema: 'backoffice',
    key: ['number'],
  },
  {
    number: T.String(),
    name: T.String(),
  },
);
export const SaleChannel = T.OurEnum(
  {
    shopee: {},
    tokopedia: {},
    tikTok: {},
    lazada: {},
    blibli: {},
    zalora: {},
    b2b: {},
    offline: {},
    reseller: {},
    others: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const BusinessId = T.IdNumber();
export const Business = PrismaModel(
  {
    name: 'Business',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Client of a loan',
  },
  {
    id: T.AutoIncrement(BusinessId),
    name: T.String(),
    saleChannels: T.Array(SaleChannel),
  },
);

export const LoanStatus = T.OurEnum(
  {
    current: {},
    paidOff: {},
    delinquent: {},
    restructured: {},
    writtenOff: {},
  },
  {
    dbSchema: 'backoffice',
    default: 'current',
  },
);

export const LoanActionId = T.IdNumber();

export const Loan = PrismaModel(
  {
    name: 'Loan',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['businessId', 'orderNumber']],
    indexes: [['employeeId']],
    description: 'Disbursement description',
  },
  {
    id: { ...ApplicationId, title: 'ID' },
    businessId: BusinessId,
    createdAt: T.CreatedAt({
      description: 'Date and time of the record creation',
    }),
    orderNumber: T.Integer({
      description: 'Order numbers of the loans disbursed to the same applicant ',
    }),
    name: T.String(),
    appealName: T.String(),
    date: T.LocalDate({ description: 'Disbursement date' }),
    specialFirstRepaymentDate: T.Nullable(T.LocalDate()),
    loanAgreementNumber: T.Unique(T.String()),
    restructureId: T.Nullable(
      T.Integer({
        description: 'ID number of a new loan that should be repayed insted of the current one',
      }),
    ),
    writeOffDate: T.Nullable(T.LocalDate()),
    termsId: T.Unique({ ...TermsSuggestionId, description: 'Selected Loan Terms' }),
    vaNumber: T.String({ title: 'Virtual account' }),
    employeeId: EmployeeId,
    personInChargeId: T.Nullable(EmployeeId),
    assignmentId: T.Nullable({
      ...LoanActionId,
      description:
        'Last assignment made with respect of the loan. Exception: if a field visit was assigned and it was finished, this record will   \nbe reset.  All assignments: LoanAction table',
    }),
    endDate: T.LocalDate(),
    status: LoanStatus,
    repaymentAmount: T.Money(),
    repaidAmount: T.Money(),
    lastPaymentDate: T.Nullable(T.LocalDate()),
    lastInvoiceDate: T.Nullable(T.LocalDate()),
    paymentOverdueDate: T.Nullable(T.LocalDate()),
    isLastCollectionByAmg: T.Boolean({ default: false }),
  },
);
export const RelLoanRestructure = PrismaRelation(
  Loan,
  'restructure',
  ['restructureId'],
  'Nullable',
  Loan,
  'restructureFor',
  ['id'],
  'Array',
);
export const RelLoanUnderwriting = PrismaRelation(
  Loan,
  'underwriting',
  ['id'],
  '',
  Underwriting,
  'loan',
  ['id'],
  'Nullable',
);
export const RelLoanVirtualAccount = PrismaRelation(
  Loan,
  'virtualAccount',
  ['vaNumber'],
  '',
  VirtualAccount,
  'loans',
  ['number'],
  'Array',
);
export const RelLoanBusiness = PrismaRelation(Loan, 'business', ['businessId'], '', Business, 'loans', ['id'], 'Array');
export const RelLoanTerms = PrismaRelation(Loan, 'terms', ['termsId'], '', TermsSuggestion, 'loan', ['id'], 'Nullable');
export const RelLoanPersonInCharge = PrismaRelation(
  Loan,
  'personInCharge',
  ['personInChargeId'],
  'Nullable',
  Employee,
  'inChargeLoan',
  ['id'],
  'Array',
);
export const RelLoanEmployee = PrismaRelation(
  Loan,
  'employee',
  ['employeeId'],
  '',
  Employee,
  'createdLoans',
  ['id'],
  'Array',
);

export const LoanActionType = T.OurEnum(
  {
    comment: {},
    assignment: {},
    fieldVisit: {},
  },
  {
    dbSchema: 'backoffice',
  },
);
export const LoanAction = PrismaModel(
  {
    name: 'LoanAction',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['loanId']],
    description: 'Activities related to loans',
  },
  {
    id: T.AutoIncrement(LoanActionId),
    type: LoanActionType,
    loanId: ApplicationId,
    date: T.CreatedAt(),
    comment: T.RichText(),
    handler: T.Nullable(T.String({ title: '3rd Party Handling' })),
    promiseToPayDate: T.Nullable(T.LocalDate()),
    promiseToPayAmount: T.Nullable(T.Money()),
    assigneeId: T.Nullable({
      ...EmployeeId,
      description: 'Employee that have been assigned to an activity\n# Assignee',
    }),
    reporterId: {
      ...EmployeeId,
      description: 'Employee who created the record (assignment / comment/ confirmed field visit)',
    },
  },
);
export const RelLoanActionAssignee = PrismaRelation(
  LoanAction,
  'assignee',
  ['assigneeId'],
  'Nullable',
  Employee,
  'loanActionAssignees',
  ['id'],
  'Array',
);
export const RelLoanActionReporter = PrismaRelation(
  LoanAction,
  'reporter',
  ['reporterId'],
  '',
  Employee,
  'loanActionReporters',
  ['id'],
  'Array',
);
export const RelLoanAssignment = PrismaRelation(
  Loan,
  'assignment',
  ['assignmentId'],
  'Nullable',
  LoanAction,
  'currentlyAssignedTo',
  ['id'],
  'Array',
);
export const RelLoanActionLoan = PrismaRelation(
  LoanAction,
  'loan',
  ['loanId'],
  '',
  Loan,
  'actions',
  ['id'],
  'Array',
  'Cascade',
);

export const CollectionVisit = PrismaModel(
  {
    name: 'CollectionVisit',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: LoanActionId,
    isBusinessOperational: T.Boolean({
      title: 'Is business still operational',
    }),
    isClientInTheStore: T.Boolean({ title: 'Is client in the store' }),
    inventoryCondition: FieldVisitEyeTest,
  },
);
export const RelCollectionVisitAssignment = PrismaRelation(
  CollectionVisit,
  'assignment',
  ['id'],
  '',
  LoanAction,
  'collectionVisit',
  ['id'],
  'Nullable',
);

export const LoanRestructure = PrismaModel(
  {
    name: 'LoanRestructure',
    dbSchema: 'backoffice',
    key: ['loanId', 'date'],
    description: 'Trash. Not used anymore',
  },
  {
    loanId: ApplicationId,
    date: T.LocalDate(),
    loanAgreementNumber: T.Nullable(T.String()),
    termsId: T.Unique(T.Integer()),
  },
);
export const RelLoanRestructureLoan = PrismaRelation(
  LoanRestructure,
  'loan',
  ['loanId'],
  '',
  Loan,
  'restructures',
  ['id'],
  'Array',
);
export const RelLoanRestructureTerms = PrismaRelation(
  LoanRestructure,
  'terms',
  ['termsId'],
  '',
  TermsSuggestion,
  'selectingRestructure',
  ['id'],
  'Nullable',
);
