import { ApplicationId } from '../onboarding';
import { Underwriting } from './common';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';

export const PostalCode = PrismaModel(
  {
    name: 'PostalCode',
    dbSchema: 'public',
    key: ['id'],
    description: 'Indonesian postal code',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    province: T.String(),
    cityDistrict: T.String(),
    subdistrict: T.String(),
    villageSubdistrict: T.String(),
    code: T.Integer(),
  },
);

export const Kbli = PrismaModel(
  {
    name: 'Kbli',
    dbSchema: 'backoffice',
    key: ['code'],
    description: 'KBLI codes directory\nIndonesia only',
  },
  {
    code: T.String(),
    parentCode: T.Nullable(T.String()),
    kbliId: T.Unique(T.String()),
    title: T.String(),
    description: T.String(),
    originalTitle: T.String(),
    originalDesc: T.String(),
  },
);
export const RelKbliParent = PrismaRelation(
  Kbli,
  'parent',
  ['parentCode'],
  'Nullable',
  Kbli,
  'children',
  ['code'],
  'Array',
);

export const UnderwritingRequestDescriptionData = PrismaModel(
  {
    name: 'UnderwritingRequestDescriptionData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: ApplicationId,
    organizationName: T.Nullable(T.String({ title: 'Name of organization' })),
    npwp: T.Nullable(T.Npwp({ title: 'NPWP' })),
    businessRegistrationDate: T.Nullable(T.LocalDate({ title: 'Date of business registration' })),
    generalDirectorAssumptionDate: T.Nullable(
      T.LocalDate({
        title: 'Date of assumption of the position of General Director',
      }),
    ),
    foundersJoiningDate: T.Nullable(T.LocalDate({ title: 'Date of joining the founders' })),
    founderFirstBusinessRegistrationDate: T.Nullable(
      T.LocalDate({
        title: "Date of registration of the founder's first business",
      }),
    ),
    businessRegistrationPlace: T.Nullable(T.String({ title: 'Place of business registration' })),
    kbliText: T.Nullable(T.String()),
    affilatedCompanies: T.Nullable(T.String({ title: 'Affiliated companies' })),
  },
);

export const RelKbliUnderwritingRequestDescriptionData = PrismaRelation(
  Kbli,
  'underwritingRequestDescriptionData',
  [],
  'Array',
  UnderwritingRequestDescriptionData,
  'kbli',
  [],
  'Array',
);
export const RelUnderwritingRequestDescriptionDataUnderwriting = PrismaRelation(
  UnderwritingRequestDescriptionData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'requestDescriptionData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingInterviewData = PrismaModel(
  {
    name: 'UnderwritingInterviewData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: ApplicationId,
    actualBusinessAddress: T.Nullable(T.String()),
    website: T.Nullable(T.Uri()),
    activitiesDetail: T.Nullable(T.String({ title: 'Activities in detail' })),
    buyers: T.Nullable(T.String({ title: 'Who are the buyers' })),
    loanPurposeDescription: T.Nullable(T.String({ title: 'Detailed description of the purpose of the loan' })),
    customerRequestAmount: T.Nullable(T.Money({ title: "Customer's request in the application form (amount)" })),
  },
);
export const RelUnderwritingInterviewDataUnderwriting = PrismaRelation(
  UnderwritingInterviewData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'interviewData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingMainMarketplaceData = PrismaModel(
  {
    name: 'UnderwritingMainMarketplaceData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: ApplicationId,
    followersCount: T.Nullable(T.Integer({ title: 'Number of followers' })),
    reviewsCount: T.Nullable(T.Integer({ title: 'Number of reviews' })),
    reputationLevel: T.Nullable(T.String()),
    chatResponseRate: T.Nullable(T.IntPercent({ title: 'Chat response rate' })),
    avarageRating: T.Nullable(T.Number({ title: 'Average rating' })),
  },
);

export const RelUnderwritingMainMarketplaceDataUnderwriting = PrismaRelation(
  UnderwritingMainMarketplaceData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'mainMarketplaceData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingContractorsData = PrismaModel(
  {
    name: 'UnderwritingContractorsData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: ApplicationId,
    avgCustomers6Months: T.Nullable(
      T.Number({
        title: 'Average number of customers for 6 months',
        unit: 'pcs',
      }),
    ),
    mainCustomerShare6Months: T.Nullable(T.Percent({ title: 'Share of the main customer for 6 months' })),
    avgSuppliers6Months: T.Nullable(
      T.Number({
        title: 'Average number of suppliers for 6 months',
        unit: 'pcs',
      }),
    ),
    mainSupplierShare6Months: T.Nullable(T.Percent({ title: 'Share of the main supplier for 6 months' })),
  },
);
export const RelUnderwritingContractorsDataUnderwriting = PrismaRelation(
  UnderwritingContractorsData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'contractorsData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingPrevYearMonthlyRevenue = PrismaModel(
  {
    name: 'UnderwritingPrevYearMonthlyRevenue',
    dbSchema: 'backoffice',
    key: ['financialId'],
    description: 'Indonesia only',
  },
  {
    financialId: ApplicationId,
    september: T.Nullable(T.Money({ title: 'September' })),
    august: T.Nullable(T.Money({ title: 'August' })),
    july: T.Nullable(T.Money({ title: 'July' })),
    june: T.Nullable(T.Money({ title: 'June' })),
    may: T.Nullable(T.Money({ title: 'May' })),
    april: T.Nullable(T.Money({ title: 'April' })),
    march: T.Nullable(T.Money({ title: 'March' })),
    february: T.Nullable(T.Money({ title: 'February' })),
    january: T.Nullable(T.Money({ title: 'January' })),
    december: T.Nullable(T.Money({ title: 'December' })),
    november: T.Nullable(T.Money({ title: 'November' })),
    october: T.Nullable(T.Money({ title: 'October' })),
  },
);
export const UnderwritingFinancialData = PrismaModel(
  {
    name: 'UnderwritingFinancialData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: ApplicationId,
    previousYearRevenue: T.Nullable(T.Money({ title: 'Revenue for previous year' })),
    currentYearRevenue: T.Nullable(T.Money({ title: 'Revenue for current year' })),
    grossMargin: T.Nullable(T.Percent({ title: 'Gross margin' })),
    operatingMargin: T.Nullable(T.Percent({ title: 'Operating margin' })),
    avgMonthlyRevenue6Months: T.Nullable(T.Money({ title: 'Average monthly revenue for 6 months' })),
    avgMonthlyRevenue12Months: T.Nullable(T.Money({ title: 'Average monthly revenue for 12 months' })),
    avgTransactions6Months: T.Nullable(T.Integer({ title: 'Average transactions for 6 months' })),
    monthsWithZeroRevenue12Months: T.Nullable(T.Integer({ title: 'Number of months with zero Revenue (12 months)' })),
  },
);
export const RelUnderwritingPrevYearMonthlyRevenueUnderwritingFinancialData = PrismaRelation(
  UnderwritingPrevYearMonthlyRevenue,
  'underwritingFinancialData',
  ['financialId'],
  '',
  UnderwritingFinancialData,
  'prevYearMonthlyRevenue',
  ['underwritingId'],
  'Nullable',
  'Cascade',
);
export const RelUnderwritingFinancialDataUnderwriting = PrismaRelation(
  UnderwritingFinancialData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'financialData',
  ['id'],
  'Nullable',
  'Cascade',
);

export const UnderwritingCreditHistoryData = PrismaModel(
  {
    name: 'UnderwritingCreditHistoryData',
    dbSchema: 'backoffice',
    key: ['underwritingId'],
    description: 'Indonesia only',
  },
  {
    underwritingId: ApplicationId,
    totalDebtLegalEntitiesFounders: T.Nullable(
      T.Money({
        title: 'Total debt on all loans and credits of legal entities and founders',
      }),
    ),
    maxLoanServicing12Months: T.Nullable(
      T.Money({
        title: 'Maximum amount of single-entity servicing of loans and borrowings for 12 months',
      }),
    ),
    monthlyPaymentLegalEntitiesFounders: T.Nullable(
      T.Money({
        title: 'Monthly payment on all loans and credits of legal entities and founders',
      }),
    ),
    overduePaymentsLoans12MonthsUpTo30Days: T.Nullable(
      T.Integer({
        title: 'Overdue payments and loans for 12 months up to 30 days',
        unit: 'pcs',
      }),
    ),
    overduePaymentsLoans24MonthsMoreThan60Days: T.Nullable(
      T.Integer({
        title: 'Overdue payments and loans for 24 months more than 60 days',
        unit: 'pcs',
      }),
    ),
    loansCreditsReceivedLastMonth: T.Nullable(
      T.Integer({
        title: 'Number of loans and credits received during the month prior to application',
        unit: 'pcs',
      }),
    ),
    amountLoansCreditsReceivedLastMonth: T.Nullable(
      T.Money({
        title: 'Amount of loans and credits received for the month prior to application',
      }),
    ),
    borrowerCreditBurdenRelativeToAvgMonthlyRevenue: T.Nullable(
      T.IntPercent({
        title: "Borrower's credit burden in relation to average monthly revenue",
      }),
    ),
    monthlyPaymentsLoansCreditsRelativeToAvgMonthlyRevenue: T.Nullable(
      T.IntPercent({
        title: 'Monthly payments on loans and credits relative to average monthly revenue',
      }),
    ),
    pdlLoans12Months: T.Nullable(T.Integer({ title: 'Number of PDL for 12 months', unit: 'pcs' })),
    dscr: T.Nullable(T.Integer({ title: 'DSCR, xN of monthly revenue' })),
    isShopeeDebitor: T.Boolean({ default: false }),
  },
);
export const RelUnderwritingCreditHistoryDataUnderwriting = PrismaRelation(
  UnderwritingCreditHistoryData,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'creditHistoryData',
  ['id'],
  'Nullable',
  'Cascade',
);
