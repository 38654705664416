import type { SimpleFormattable } from '../base';
import { createType, FormatFuncKind } from '../base';
import * as Type from '../vendorType';

interface TLiteralUnion<T extends Type.TLiteralValue[]>
  extends SimpleFormattable,
    Type.TUnion<{ [key in keyof T]: Type.TLiteral<T[key]> }> {}

export const LiteralUnion = <const T extends Type.TLiteralValue[]>(
  values: T,
  opts?: Type.SchemaOptions,
): TLiteralUnion<T> =>
  createType(
    Type.Union(
      values.map((v) => Type.Literal(v)),
      opts,
    ),
    { [FormatFuncKind]: String },
  );
