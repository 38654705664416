import type { StaticDecode, TArray, TSchema } from '@sinclair/typebox';
import type { Component, FunctionalComponent } from 'vue';
import { defineAsyncComponent, h } from 'vue';

export type IfDefined<T> = T extends undefined ? NonNullable<unknown> : NonNullable<T>;

// These must be `Symbol.for()` and not `Symbol`, because in nuxt
// production build this variable declaration appears twice
export const LogTransformKind = Symbol.for('LogTransformKind');
export const hasCustomLogTransform = <T extends TSchema>(
  schema: T,
): schema is T & {
  [LogTransformKind]: (value: StaticDecode<T>, schema: T) => unknown;
} => LogTransformKind in schema;

export const PrismaTypeKind = Symbol.for('PrismaTypeKind');
export interface PrismaType extends TSchema {
  [PrismaTypeKind]: string;
}
export const isPrismaType = (schema: TSchema): schema is PrismaType =>
  PrismaTypeKind in schema && typeof schema[PrismaTypeKind] === 'string';

export const FormatFuncKind = Symbol.for('FormatFuncKind');
export interface FormattableSchema<in O = never> {
  [FormatFuncKind]: (value: unknown, opts: O, schema: TSchema) => string;
}
export type SimpleFormattable = FormattableSchema<undefined>;
export type FormatOptions<T> = T extends FormattableSchema<infer O> ? O : undefined;
export type FormatFunction<T extends TSchema, O> = (value: StaticDecode<T>, opts: O, schema: T) => string;
export type MaybeObjectFormatOptions<O> = Record<keyof O, undefined> extends O ? undefined | Partial<O> : O;
export const isFormattable = (schema: TSchema): schema is TSchema & FormattableSchema =>
  FormatFuncKind in schema && !!schema[FormatFuncKind];
export type MaybeFormatOptions<O> = undefined extends O ? [opts?: O] : [opts: O];
export const format = <T extends TSchema & FormattableSchema>(
  schema: T,
  value: NoInfer<StaticDecode<T>>,
  ...[opts]: NoInfer<MaybeFormatOptions<FormatOptions<T>>>
) => schema[FormatFuncKind](value, opts as never, schema);

export type FormatComponent<T extends TSchema> = Component<{
  value: StaticDecode<T>;
  schema: T;
  opts: FormatOptions<T>;
}>;
export const VueComponentKind = Symbol.for('VueComponentKind');
export const VueTableCellComponentKind = Symbol.for('VueTableCellComponentKind');

export const VueInputComponentKind = Symbol.for('VueInputComponentKind');
export interface InputtableSchema<Props = object> {
  [VueInputComponentKind]: FunctionalComponent<Props>;
}
export const isInputtable = (schema: TSchema): schema is TSchema & InputtableSchema =>
  VueInputComponentKind in schema && !!schema[VueInputComponentKind];
export const VueArrayInputComponentKind = Symbol.for('VueArrayInputComponentKind');
export interface ArrayInputtableSchema<Props = object> {
  [VueArrayInputComponentKind]: FunctionalComponent<Props>;
}
export const isArrayInputtable = (schema: TSchema): schema is TSchema & ArrayInputtableSchema =>
  VueArrayInputComponentKind in schema && !!schema[VueArrayInputComponentKind];
export type InputComponent<T extends TSchema, P> = Component<
  {
    modelValue: StaticDecode<T> | null;
    schema: T;
    id?: string;
    nullable: boolean;
    'onUpdate:modelValue': (val: StaticDecode<T>) => void;
  } & P
>;

export type AllRichTypeOpts<T extends TSchema> = {
  [LogTransformKind]?: (value: StaticDecode<T>, schema: T) => unknown;
  [VueComponentKind]?: FormatComponent<T>;
  [VueTableCellComponentKind]?: FormatComponent<T>;
  [PrismaTypeKind]?: string;
} & (T extends FormattableSchema<infer O> ? { [FormatFuncKind]: FormatFunction<T, O> } : object) &
  (T extends InputtableSchema<infer P> ? { [VueInputComponentKind]: InputComponent<T, P> } : object) &
  (T extends ArrayInputtableSchema<infer P>
    ? {
        [VueArrayInputComponentKind]: InputComponent<TArray<T>, P>;
      }
    : object);

export type RichTypeOpts<T extends TSchema> = Partial<AllRichTypeOpts<T>>;

type RichTypeOptKeys = keyof AllRichTypeOpts<TSchema & FormattableSchema & InputtableSchema & ArrayInputtableSchema>;

export const createType = <T extends TSchema, M extends AllRichTypeOpts<T>, K extends RichTypeOptKeys>(
  schema: Omit<T, K>,
  modifiers: Pick<M, keyof M & K>,
): T =>
  ({
    ...schema,
    ...modifiers,
  }) as never;

export const withRichOpts = <
  T extends TSchema,
  O extends {
    [LogTransformKind]?: (value: StaticDecode<T>, schema: T) => unknown;
    [VueComponentKind]?: FormatComponent<T>;
    [PrismaTypeKind]?: string;
    [FormatFuncKind]?: FormatFunction<T, O>;
    [VueInputComponentKind]?: InputComponent<T, object>;
    [VueArrayInputComponentKind]?: InputComponent<TArray<T>, object>;
  },
>(
  schema: T,
  modifiers: O,
): T =>
  ({
    ...schema,
    ...modifiers,
  }) as never;

export const createAsyncComponentWrapper = <C extends Component, P>(
  importComponent: () => Promise<C>,
  render: (component: C) => Component<P>,
): Component<P> => {
  let component: Component<P> | undefined;
  return ((props: never) => {
    component ??= render(defineAsyncComponent<C>(importComponent));
    return h(component, props);
  }) as never;
};

export const defineFC = <C extends Component>(c: C, opts: { emits: string[] }): C => Object.assign(c, opts);
