import { Employee, EmployeeId } from './auth';
import { ApplicationId, Onboarding, OnboardingCoowner, OnboardingCoownerId } from './onboarding';
import { Underwriting } from './underwriting';
import { PrismaModel, PrismaRelation, T } from '@getmo/common/typebox';

export const PhCreditHistoryId = T.IdNumber();
export const PhCreditHistory = PrismaModel(
  {
    name: 'PhCreditHistory',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Phillipines. Credit History',
  },
  {
    id: T.AutoIncrement(PhCreditHistoryId),
    createdAt: T.CreatedAt(),
    score: T.Nullable(T.Integer(), { nullableFallback: 'No credit history' }),
    reasons: T.Array(T.String()),
    rawResult: T.Any({ description: 'Total information from Credit Bureau. ' }),
    onboardingId: ApplicationId,
  },
);
export const RelPhCreditHistoryOnboarding = PrismaRelation(
  PhCreditHistory,
  'onboarding',
  ['onboardingId'],
  '',
  Onboarding,
  'phCreditHistory',
  ['id'],
  'Array',
  'Cascade',
);

const CreditHistoryId = T.IdNumber();
export const CreditHistory = PrismaModel(
  {
    name: 'CreditHistory',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Indonesia. All credit histories submitted',
  },
  {
    id: T.AutoIncrement(CreditHistoryId),
    status: T.SmallInt(),
    statusText: T.String(),
    createdAt: T.Date(),
    pdfRequestId: T.Unique(T.Uuid()),
    coownerId: T.Nullable(OnboardingCoownerId),
    corporate: T.Boolean({ default: false }),
    employeeId: T.Nullable(EmployeeId),
    underwritingId: ApplicationId,
  },
);

export const RelCreditHistoryEmployee = PrismaRelation(
  CreditHistory,
  'employee',
  ['employeeId'],
  'Nullable',
  Employee,
  'creditHistories',
  ['id'],
  'Array',
);
export const RelCreditHistoryUnderwriting = PrismaRelation(
  CreditHistory,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'creditHistory',
  ['id'],
  'Array',
  'Cascade',
);
export const RelCreditHistoryCoowner = PrismaRelation(
  CreditHistory,
  'coowner',
  ['coownerId'],
  'Nullable',
  OnboardingCoowner,
  'creditHistory',
  ['id'],
  'Array',
  'Cascade',
);

export const CreditHistoryLoan = PrismaModel(
  {
    name: 'CreditHistoryLoan',
    dbSchema: 'backoffice',
    key: ['creditHistoryId', 'contractCode'],
    uniques: [['creditHistoryId', 'contractCode']],
    description: 'Indonesia. Credit History, loans description',
  },
  {
    id: T.String(),
    createdAt: T.Date(),
    customerId: T.String(),
    contractCode: T.String(),
    namaLender: T.String(),
    role: T.String(),
    startDate: T.LocalDate(),
    dueDate: T.Nullable(T.LocalDate()),
    contractStatus: T.String(),
    economicSector: T.Nullable(T.String()),
    interestRateAnnual: T.String(),
    initialCreditLimit: T.Money(),
    creditLimit: T.Money(),
    debitBalance: T.Money(),
    interest: T.Integer(),
    tenure: T.Nullable(T.Integer()),
    closed: T.Boolean(),
    creditHistoryId: CreditHistoryId,
  },
);
export const RelCreditHistoryLoanCreditHistory = PrismaRelation(
  CreditHistoryLoan,
  'creditHistory',
  ['creditHistoryId'],
  '',
  CreditHistory,
  'loans',
  ['id'],
  'Array',
  'Cascade',
);

export const CreditHistoryLoanHistoricalData = PrismaModel(
  {
    name: 'CreditHistoryLoanHistoricalData',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Indonesia. Credit History, historical data',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    referenceDate: T.String(),
    daysPastDue: T.Nullable(T.Integer()),
    overdue: T.Nullable(T.Money()),
    debitBalance: T.Nullable(T.Money()),
    currentMonthRealization: T.Nullable(T.Money()),
    contractStatus: T.Nullable(T.String()),
    creditHistoryLoanContractCode: T.String(),
    creditHistoryId: CreditHistoryId,
  },
);
export const RelCreditHistoryLoanHistoricalDataCreditHistoryLoan = PrismaRelation(
  CreditHistoryLoanHistoricalData,
  'creditHistoryLoan',
  ['creditHistoryId', 'creditHistoryLoanContractCode'],
  '',
  CreditHistoryLoan,
  'historicalData',
  ['creditHistoryId', 'contractCode'],
  'Array',
  'Cascade',
);

export const CreditHistorySlackRequest = PrismaModel(
  {
    name: 'CreditHistorySlackRequest',
    dbSchema: 'backoffice',
    key: ['id'],
    description: 'Indonesia only',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    threadTs: T.String(),
    isCorporate: T.Boolean(),
    underwritingId: ApplicationId,
    coownerId: T.Nullable(OnboardingCoownerId),
  },
);
export const RelCreditHistorySlackRequestUnderwriting = PrismaRelation(
  CreditHistorySlackRequest,
  'underwriting',
  ['underwritingId'],
  '',
  Underwriting,
  'creditHistorySlackRequests',
  ['id'],
  'Array',
  'Cascade',
);
export const RelCreditHistorySlackRequestCoowner = PrismaRelation(
  CreditHistorySlackRequest,
  'coowner',
  ['coownerId'],
  'Nullable',
  OnboardingCoowner,
  'creditHistorySlackRequests',
  ['id'],
  'Array',
  'Cascade',
);
