import { PrismaModel, PrismaRelation, T } from '../../typebox';

export const AdLevel = T.OurEnum(
  {
    account: {},
    campaign: {},
    adSet: {},
    ad: {},
  },
  { dbSchema: 'backoffice' },
);

export const TimeGrain = T.OurEnum(
  {
    day: {},
    week: {},
    month: {},
    quarter: {},
    year: {},
  },
  { dbSchema: 'public' },
);

export const AdSettings = PrismaModel(
  {
    name: 'AdSettings',
    dbSchema: 'backoffice',
    key: ['id'],
  },
  {
    id: T.String(),
    level: AdLevel,
    name: T.String(),
    parentId: T.Nullable(T.String()),
  },
);

export const AdMetrics = PrismaModel(
  {
    name: 'AdMetrics',
    dbSchema: 'backoffice',
    key: ['adId', 'timeGrain', 'date'],
  },
  {
    adId: T.String(),
    timeGrain: TimeGrain,
    date: T.LocalDate(),
    uniqueLinkClicks: T.Integer(),
    spend: T.Money(),
  },
);

export const RelAdSettingsParent = PrismaRelation(
  AdSettings,
  'parent',
  ['parentId'],
  'Nullable',
  AdSettings,
  'children',
  ['id'],
  'Array',
  'Cascade',
);

export const RelAdMetricsAdSettings = PrismaRelation(
  AdMetrics,
  'adSettings',
  ['adId'],
  '',
  AdSettings,
  'metrics',
  ['id'],
  'Array',
  'Cascade',
);

export const SitePage = T.OurEnum(
  {
    landing: {},
    onboarding: {},
    onboardingOtp: {},
  },
  { dbSchema: 'backoffice' },
);

export const SiteMetrics = PrismaModel(
  {
    name: 'SiteMetrics',
    dbSchema: 'backoffice',
    key: ['id'],
    uniques: [['timeGrain', 'date', 'page', 'utmSource', 'utmCampaign']],
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    timeGrain: TimeGrain,
    date: T.LocalDate(),
    page: SitePage,
    utmSource: T.String(),
    utmCampaign: T.String(),
    visitors: T.Integer(),
  },
);
