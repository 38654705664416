import type { FormattableSchema, InputtableSchema, MaybeObjectFormatOptions, RichTypeOpts } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  VueInputComponentKind,
} from '../base';
import * as Type from '@sinclair/typebox';
import type { CheckboxProps } from 'primevue/checkbox';
import { h } from 'vue';

export interface TBoolean
  extends Type.TBoolean,
    FormattableSchema<
      MaybeObjectFormatOptions<{
        trueLabel?: string;
        falseLabel?: string;
      }>
    >,
    InputtableSchema<CheckboxProps> {}

export const { IsBoolean } = Type.TypeGuard;

const richOpts = {
  [FormatFuncKind]: (value, { trueLabel = 'Yes', falseLabel = 'No' } = {}) => (value ? trueLabel : falseLabel),
  [PrismaTypeKind]: 'Boolean',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/checkbox'),
    (C) => (props) => h(C, { binary: true, ...props }),
  ),
} satisfies RichTypeOpts<TBoolean>;
export const Boolean = (opts?: Type.SchemaOptions): TBoolean => createType(Type.Boolean(opts), richOpts);
