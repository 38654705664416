import { PrismaModel, T } from '../../typebox';

export const MessageChannel = T.OurEnum(
  {
    sms: {},
    email: {},
  },
  {
    dbSchema: 'backoffice',
  },
);

export const MessageStatus = T.OurEnum(
  {
    queued: {},
    queuingFailed: {},
    sent: {},
    deliveredToOperator: {},
    deliveredToRecipient: {},
    rejectedByOperator: {},
    rejectedByRecipient: {},
    read: {},
    ok: {},
    error: {},
    unknown: {},
  },
  {
    dbSchema: 'backoffice',
  },
);

export const MessageDirection = T.OurEnum(
  {
    inbound: {},
    outbound: {},
  },
  {
    dbSchema: 'backoffice',
  },
);

export const NotificationType = T.OurEnum(
  {
    onboardingReminder: {},
    applicationReceived: {},
    loanApprovement: {},
    repaymentReminder: {},
    repaymentReceived: {},
    fieldVisitWarning: {},
  },
  {
    dbSchema: 'public',
  },
);

export const Message = PrismaModel(
  {
    name: 'Message',
    dbSchema: 'backoffice',
    key: ['id'],
    indexes: [['destination'], ['externalId']],
    description: 'Communication message with a client. SMS, Email',
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    externalId: T.Unique(T.String()),
    channel: MessageChannel,
    notificationType: T.Nullable(NotificationType),
    direction: MessageDirection,
    status: MessageStatus,
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    text: T.Multiline(),
    destination: T.String(),
    price: T.Nullable(T.Money()),
    errorCode: T.Nullable(T.Integer()),
    errorMessage: T.Nullable(T.String()),
  },
);

export const NotificationUnsubscription = PrismaModel(
  {
    name: 'NotificationUnsubscription',
    dbSchema: 'public',
    key: ['email', 'type'],
  },
  {
    email: T.String(),
    type: NotificationType,
    createdAt: T.CreatedAt(),
  },
);

export const SmsSlackThread = PrismaModel(
  {
    name: 'SmsSlackThread',
    dbSchema: 'backoffice',
    key: ['phone'],
  },
  {
    phone: T.String(),
    slackThreadId: T.String(),
  },
);
