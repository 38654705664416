import { isValid } from 'psl';
import * as Yup from 'yup';
import { phone } from './phone';

export const yup = {
  init: () => {
    Yup.setLocale({
      mixed: {
        required: 'This field is required',
      },
      string: {
        email: 'Must be a valid email',
      },
      array: {
        min: ({ min }) => `Choose at least ${min} option`,
      },
    });
  },
  uploadMinFile: (count: number) => `Upload at least ${count} file${count > 1 ? 's' : ''}`,
  uploadFile: () => `Uploading file is required`,
  validatePhone: () =>
    Yup.string()
      .required()
      .test('isPhone', 'Not a valid phone number', (value) => phone.isValid(value)),
  validateEmail: () =>
    Yup.string()
      .matches(/^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim, {
        name: 'email',
        message: Yup.defaultLocale.string?.email,
        excludeEmptyString: true,
      })
      .test('domain', 'Must be a valid email', (v) => {
        const domain = v?.split('@')[1];
        return domain ? isValid(domain) : false;
      }),
};
