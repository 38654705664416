import { useFormikContext } from 'formik';
import { AllHTMLAttributes, useCallback, useMemo, useRef } from 'react';
import { formik } from '../../../../utils/formik';
import { MimeType } from '../../../types/mimeType';
import { DocumentFileInput } from './DocumentFileInput';
import { UploadedFilePreview } from './UploadedFilePreview';
import { R } from '@getmo/common/vendor/remeda';
import { useApplication } from '../../../../auth/applicationContext';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';

export type UploadFunction = (file: File) => Promise<FullDocument | null>;
export type OnUploadedFunction = (files: FullDocument[]) => void;
export type DeleteFunction = (id: number) => Promise<void>;
export type OnDeletedFunction = (id: number) => void;

export interface FileInputOptionProps {
  multiple?: boolean;
  mimeTypes?: MimeType[];
  maxFiles?: number;
  capture?: AllHTMLAttributes<HTMLInputElement>['capture'];
}

export interface UploadedFilePreviewProps {
  key: string;
  file: FullDocument;
  deleteFile: DeleteFunction;
  onDeletedFile: OnDeletedFunction;
}

interface Props extends FileInputOptionProps {
  inputName: string;
  upload: UploadFunction;
  deleteFile: DeleteFunction;
  uploadedFilePreview?: (props: UploadedFilePreviewProps) => JSX.Element;
  extractImageFromPdf?: 'ktp' | 'npwp';
  label?: string;
  subtitle?: string | JSX.Element;
  header?: JSX.Element;
  isRequired?: boolean;
  showInfoAlert?: boolean;
}

export const useFileUpload = ({
  inputName,
  upload,
  deleteFile,
  label,
  subtitle,
  header,
  showInfoAlert,
  isRequired = false,
  ...fileOpts
}: Props) => {
  const formikCtx = useFormikContext();
  if (!formikCtx) {
    console.error('file input should be wrapped by formik provider');
  }

  const { multiple = true, maxFiles = 12, mimeTypes, extractImageFromPdf, capture } = fileOpts;
  const uploaded: FullDocument[] = R.pathOr(formikCtx?.values || {}, R.stringToPath(inputName), [] as never);
  const uploadedRef = useRef<FullDocument[]>([]);
  uploadedRef.current = uploaded;

  if (!Array.isArray(uploaded)) {
    console.error(`"values.${inputName}" should be an array`);
  }

  const isDisabled = useMemo(
    () => (multiple ? uploaded.length >= maxFiles : !!uploaded.length),
    [uploaded, multiple, maxFiles],
  );

  const error = formik.getError(
    R.pathOr(formikCtx?.errors || [], R.stringToPath(inputName), undefined as never),
    R.pathOr(formikCtx?.touched, R.stringToPath(inputName), undefined as never),
  );

  const onUploaded: OnUploadedFunction = useCallback(
    (files) => {
      uploadedRef.current = [...uploadedRef.current, ...files];
      formikCtx?.setFieldValue(inputName, uploadedRef.current);
    },
    [formikCtx],
  );

  const { setApplication } = useApplication();
  const onDeleted: OnDeletedFunction = (uploadedId) => {
    const document = uploadedRef.current.find((d) => d.id === uploadedId);
    if (!document) {
      return;
    }

    uploadedRef.current = uploadedRef.current.filter((d) => d.id !== document.id);
    formikCtx?.setFieldValue(inputName, uploadedRef.current);
    setApplication((a) => ({ ...a, documents: a.documents.filter((d) => d.id !== document.id) }));
  };

  const element = (
    <DocumentFileInput
      inputName={inputName}
      upload={upload}
      onUploaded={onUploaded}
      isDisabled={isDisabled}
      isMultiple={multiple}
      mimeTypes={mimeTypes}
      extractImageFromPdf={extractImageFromPdf}
      isRequired={isRequired}
      formLabel={label}
      subtitle={subtitle}
      header={header}
      error={error}
      hasUploaded={!!uploadedRef.current.length}
      showInfoAlert={showInfoAlert}
      capture={capture}
    />
  );

  const previews = useMemo(
    () =>
      uploaded.map((file) => (
        <UploadedFilePreview key={`file-${file.id}`} file={file} deleteFile={deleteFile} onDeletedFile={onDeleted} />
      )),
    [uploaded],
  );

  return { element, previews };
};
