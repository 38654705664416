import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { yup } from '../../../utils/yup';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { FullDocument } from '@getmo/onboarding/schemas/models/documents';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { useApplication } from '../../../auth/applicationContext';
import { $Enums } from '@getmo/common/prisma';
import { R } from '@getmo/common/vendor/remeda';
import { defaultMimeTypes } from '../../types/mimeType';

interface InitialValues {
  files: {
    taxReport: FullDocument[];
    bankStatementCorporate: FullDocument[];
  };
}

const PhFinancialData: FC = () => {
  const title = <>Financial Data</>;
  const subtitle = (
    <>
      To facilitate a smooth loan application process and ensure we offer the best financial solutions, we kindly
      request the following financial information. We want to assure you that the security of your data is our utmost
      priority.
    </>
  );

  const { goToNextStep } = useNextStep();
  const { application, setApplication } = useApplication();
  const initialValues = useMemo<InitialValues>(
    () => ({
      files: {
        taxReport: application.documents.filter((d) => d.type === $Enums.DocumentType.taxReport && !d.coownerId),
        bankStatementCorporate: application.documents.filter(
          (d) => d.type === $Enums.DocumentType.bankStatementCorporate && !d.coownerId,
        ),
      },
    }),
    [application.documents],
  );

  const validation = Yup.object({
    files: Yup.object({
      taxReport: Yup.array().min(1, yup.uploadMinFile(1)),
      bankStatementCorporate: Yup.array().min(1, yup.uploadMinFile(1)),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const { showError } = useSnackbar();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      const files = R.values(values.files).flat();
      const uploadIds = files.map((u) => u.id);
      setApplication((a) => ({
        ...a,
        documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
      }));

      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <DocumentFileInput
                    name="files.taxReport"
                    label="Latest AFS/ITR 2023"
                    docType={$Enums.DocumentType.taxReport}
                    mimeTypes={defaultMimeTypes.taxReport}
                    required
                  />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.bankStatementCorporate"
                    label="Latest 3 months Bank Statements"
                    docType={$Enums.DocumentType.bankStatementCorporate}
                    mimeTypes={defaultMimeTypes.bankStatementCorporate}
                    required
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhFinancialData;
