import { FC } from 'react';
import { type GeoDivision } from '@getmo/onboarding/schemas/models/dictionaries';
import { FormControl, FormHelperText, FormLabel, Popper, PopperProps, TextField } from '@mui/material';
import { Field as FormikField, FieldProps } from 'formik';
import { $Enums } from '@getmo/common/prisma';
import TreeSelect from 'mui-tree-select';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { dictionariesThunk } from '../../../store/reducers/thunks';

type Division = GeoDivision & {
  parent?: Division | null;
  children?: Division[];
};

export const GeoDivisionSelect: FC = () => {
  const dispatch = useAppDispatch();
  const divisions = useAppSelector((state) => state.dictionaries.geoDivisions);
  const handleGetItems = (division: Division | null) => {
    const code = division?.code ?? null;
    return divisions[code ?? ''] ?? dispatch(dictionariesThunk.loadGeoDivisions({ parentDivision: division })).unwrap();
  };

  return (
    <FormikField name="geoDivision">
      {({ field, form, meta }: FieldProps<GeoDivision | null>) => (
        <>
          <FormLabel
            required
            sx={{
              mb: '5px',
              display: 'inline-block',
              fontSize: '1.1rem',
              fontWeight: '500',
            }}
          >
            Your Business Location - City/Province/Barangay
          </FormLabel>
          <FormControl error={!!(meta.touched && meta.error)} fullWidth>
            <TreeSelect<Division>
              PopperComponent={BottomPopper}
              getChildren={handleGetItems}
              getParent={(node) => node.parent}
              getOptionLabel={(node) => node.name}
              getOptionKey={(node) => node.code}
              onChange={(_, value) => form.setFieldValue('geoDivision', value)}
              // Allow selecting only 2 levels deep
              onBranchChange={(_, value) =>
                form.setFieldValue('geoDivision', value?.type === $Enums.GeoDivisionType.barangay ? value : null)
              }
              value={field.value}
              isBranch={(node) => node.type !== $Enums.GeoDivisionType.barangay}
              renderInput={(params) => <TextField error={!!(meta.touched && meta.error)} {...params} />}
            />
            <FormHelperText>{!!(meta.touched && meta.error) || ' '}</FormHelperText>
          </FormControl>
        </>
      )}
    </FormikField>
  );
};

const BottomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom"
    modifiers={[
      {
        // Prevent popup from jumping from between top and bottom when content changes
        name: 'flip',
        enabled: false,
      },
    ]}
  />
);
