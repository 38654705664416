import { GeoDivision } from './dictionaries';
import * as DB from '@getmo/common/prisma/typebox';
import { ClientModel, ClientRelation } from '@getmo/common/typebox';

export const Onboarding = ClientModel(DB.Onboarding, [
  'id',
  'status',
  'step',

  'name',
  'email',
  'phone',

  'leadSource',
  'shopName',
  'legalCategory',
  'legalType',
  'typeOfSalesChannel',
  'idBusinessCategory',
  'idCity',
  'yearsBusinessOperation',
  'vidaKycStatus',
  'revenue',
  'loanAmount',
  'loanPurpose',
  'loanDuration',
  'phBusinessCategory',
  'geoDivisionCode',
  'location',
  'landmark',
  'geoPos',
  'phCheckingAccount',
  'phHasAfs',
  'phIsBusinessOwner',
  'phIsReadyForVisit',
  'qualification',
  'top5Suppliers',
  'outstandingDebt',
  'utm',
]);
export const OnboardingSaleChannel = ClientModel(DB.OnboardingSaleChannel, ['id', 'isSynced', 'link', 'type']);
export const PersonUnverifiedData = ClientModel(DB.PersonUnverifiedData, [
  'dob',
  'fullName',
  'personalIdNumber',
  'phPersonalIdType',
  'gender',
]);
export const OnboardingCompanyContact = ClientModel(DB.OnboardingCompanyContact, ['phone', 'email']);
export const OnboardingEmergencyContact = ClientModel(DB.OnboardingEmergencyContact, ['name', 'phone', 'relation']);
export const OnboardingCoowner = ClientModel(DB.OnboardingCoowner, ['id', 'name', 'role', 'phone', 'email']);

export const RelOnboardingSaleChannelOnboarding = ClientRelation(
  DB.RelOnboardingSaleChannelOnboarding,
  OnboardingSaleChannel,
  Onboarding,
);
export const RelPersonUnverifiedDataOnboarding = ClientRelation(
  DB.RelPersonUnverifiedDataOnboarding,
  PersonUnverifiedData,
  Onboarding,
);
export const RelOnboardingCompanyContactOnboarding = ClientRelation(
  DB.RelOnboardingCompanyContactOnboarding,
  OnboardingCompanyContact,
  Onboarding,
);
export const RelOnboardingEmergencyContactOnboarding = ClientRelation(
  DB.RelOnboardingEmergencyContactOnboarding,
  OnboardingEmergencyContact,
  Onboarding,
);
export const RelOnboardingCoownerOnboarding = ClientRelation(
  DB.RelOnboardingCoownerOnboarding,
  OnboardingCoowner,
  Onboarding,
);

export const RelOnboardingGeoDivision = ClientRelation(DB.RelOnboardingGeoDivision, Onboarding, GeoDivision);
