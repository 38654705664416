import { FullDocument } from '@getmo/onboarding/schemas/models/documents';
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { useSnackbar } from '../../components/snackbar/useSnackbar';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { PhSelfieInput } from './PhSelfieInput';
import { callApi } from '../../../api';
import { personSelfieUploadEndpoint } from '@getmo/onboarding/schemas/endpoints/personVerification';
import { useApplication } from '../../../auth/applicationContext';
import { $Enums } from '@getmo/common/prisma';
import { DocumentFileInput } from '../../components/upload/DocumentFileInput';
import { defaultMimeTypes } from '../../types/mimeType';
import * as Yup from 'yup';

interface InitialValues {
  files: {
    selfie: FullDocument[];
    storePhoto: FullDocument[];
  };
}

const PhSelfie: FC = () => {
  const title = <>Identity Verification</>;
  const subtitle = (
    <>
      Please provide the following essential details to assist us in tailoring the perfect financing solution for your
      business.
    </>
  );

  const { goToNextStep } = useNextStep();
  const { application, setApplication } = useApplication();
  const initialValues = useMemo<InitialValues>(
    () => ({
      files: {
        selfie: application.documents.filter((d) => d.type === $Enums.DocumentType.selfie && !d.coownerId),
        storePhoto: application.documents.filter((d) => d.type === $Enums.DocumentType.storePhoto),
      },
    }),
    [application.documents],
  );

  const validation = Yup.object({
    files: Yup.object({
      selfie: Yup.array().min(1, 'Please provide your selfie'),
    }),
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const [initialErrors, setInitialErrors] = useState({});
  const [selfie, setSelfie] = useState('');
  const { showError } = useSnackbar();

  const onSubmit = async (values: typeof initialValues) => {
    setInitialErrors({});
    setSubmitting(true);

    try {
      await callApi(personSelfieUploadEndpoint, {
        body: { selfiePhoto: selfie.replace(/^data:\w+\/\w+;base64,/, '') },
      });

      const files = values.files.storePhoto;
      const uploadIds = files.map((u) => u.id);
      setApplication((a) => ({
        ...a,
        documents: a.documents.filter((d) => !uploadIds.includes(d.id)).concat(files),
      }));

      await goToNextStep();
    } catch (e) {
      const { error, validationErrors } = useApiErrors(e);
      setInitialErrors(validationErrors);
      if (error) {
        showError(error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StepContent title={title} subtitle={subtitle} width="medium">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container direction="column" gap={0} pt={0}>
                <Grid item>
                  <PhSelfieInput selfie={selfie} onChange={setSelfie} />
                </Grid>
                <Grid item>
                  <DocumentFileInput
                    name="files.storePhoto"
                    label="Please upload additional photos of your business spot and products for sale"
                    docType={$Enums.DocumentType.storePhoto}
                    mimeTypes={defaultMimeTypes.storePhoto}
                    required={false}
                  />
                </Grid>
                <Grid item display="flex" justifyContent="center" pt={2}>
                  <StepSubmitButton isSubmitting={isSubmitting} />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </StepContent>
  );
};

export default PhSelfie;
