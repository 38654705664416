import { PrismaModel, PrismaRelation, T } from '../../typebox';
import { OnboardingSaleChannel, OnboardingSaleChannelId } from './onboarding';

export const ShopConnectionStatus = T.OurEnum(
  {
    connectionInit: {},
    connected: {},
    syncInit: {},
    synced: {},
    error: {},
  },
  {
    dbSchema: 'public',
  },
);

export const ShopConnection = PrismaModel(
  {
    name: 'ShopConnection',
    dbSchema: 'public',
    key: ['saleChannelId'],
    description: 'Access data for a connected marketplace',
  },
  {
    saleChannelId: OnboardingSaleChannelId,
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    lastSyncAt: T.Nullable(T.Date()),
    status: ShopConnectionStatus,
    shopId: T.Nullable(T.Integer()),
    shopName: T.Nullable(T.String()),
    accessToken: T.Nullable(T.String()),
    refreshToken: T.Nullable(T.String()),
    tokenExpiresAt: T.Nullable(T.Date()),
    refreshTokenExpiresAt: T.Nullable(T.Date()),
  },
);
export const RelShopConnectionSaleChannel = PrismaRelation(
  ShopConnection,
  'saleChannel',
  ['saleChannelId'],
  '',
  OnboardingSaleChannel,
  'shopConnection',
  ['id'],
  'Nullable',
  'Cascade',
);

export const ShopProductId = T.IdNumber();
export const ShopProduct = PrismaModel(
  {
    name: 'ShopProduct',
    dbSchema: 'public',
    key: ['id'],
    uniques: [['externalId', 'connectionId']],
    indexes: [['price']],
    description: "Client's product loaded from a marketplace connection",
  },
  {
    id: T.AutoIncrement(ShopProductId),
    category: T.Nullable(T.String()),
    name: T.String(),
    photo: T.Nullable(T.String()),
    article: T.String(),
    stock: T.Integer(),
    price: T.Decimal(),
    oldPrice: T.Nullable(T.Decimal()),
    externalId: T.String(),
    connectionId: OnboardingSaleChannelId,
  },
);
export const RelShopProductConnection = PrismaRelation(
  ShopProduct,
  'connection',
  ['connectionId'],
  '',
  ShopConnection,
  'products',
  ['saleChannelId'],
  'Array',
  'Cascade',
);

export const ShopOrderStatus = T.OurEnum(
  {
    new: {},
    shipped: {},
    delivered: {},
    canceled: {},
  },
  {
    dbSchema: 'public',
  },
);
export const ShopOrderId = T.IdNumber();
export const ShopOrder = PrismaModel(
  {
    name: 'ShopOrder',
    dbSchema: 'public',
    key: ['id'],
    uniques: [['number', 'connectionId']],
    indexes: [['createdAt'], ['amount']],
    description: "Client's sale data loaded from a marketplace connection",
  },
  {
    id: T.AutoIncrement(ShopOrderId),
    number: T.String(),
    createdAt: T.CreatedAt(),
    updatedAt: T.UpdatedAt(),
    status: ShopOrderStatus,
    amount: T.Decimal(),
    fee: T.Decimal(),
    city: T.Nullable(T.String()),
    connectionId: OnboardingSaleChannelId,
  },
);
export const RelShopOrderConnection = PrismaRelation(
  ShopOrder,
  'connection',
  ['connectionId'],
  '',
  ShopConnection,
  'orders',
  ['saleChannelId'],
  'Array',
  'Cascade',
);

export const ShopOrderItem = PrismaModel(
  {
    name: 'ShopOrderItem',
    dbSchema: 'public',
    key: ['id'],
    uniques: [['itemId', 'modelId', 'orderId']],
    description: "An item of client's sale loaded from a marketplace connection",
  },
  {
    id: T.AutoIncrement(T.IdNumber()),
    quantity: T.Integer(),
    price: T.Decimal(),
    oldPrice: T.Nullable(T.Decimal()),
    modelId: T.Nullable(T.String()),
    itemId: T.String(),
    orderId: ShopOrderId,
    productId: T.Nullable(ShopProductId),
  },
);
export const RelShopOrderItemOrder = PrismaRelation(
  ShopOrderItem,
  'order',
  ['orderId'],
  '',
  ShopOrder,
  'items',
  ['id'],
  'Array',
  'Cascade',
);
export const RelShopOrderItemProduct = PrismaRelation(
  ShopOrderItem,
  'product',
  ['productId'],
  'Nullable',
  ShopProduct,
  'orderItems',
  ['id'],
  'Array',
  'Cascade',
);
