import type { RichTypeOpts, SimpleFormattable } from '../base';
import { createType, FormatFuncKind, PrismaTypeKind } from '../base';
import * as Type from '@sinclair/typebox';

export interface TAny extends Type.TAny, SimpleFormattable {}

export const { IsAny } = Type.TypeGuard;

export const richOpts = {
  [FormatFuncKind]: (value) => JSON.stringify(value),
  [PrismaTypeKind]: 'Json',
} satisfies RichTypeOpts<TAny>;
export const Any = (opts?: Type.SchemaOptions): TAny => createType(Type.Any(opts), richOpts);
