import { genLiteralSchemaTitle } from '../../utils/formats/literal';
import type { ArrayInputtableSchema, InputtableSchema, RichTypeOpts, SimpleFormattable } from '../base';
import {
  createAsyncComponentWrapper,
  createType,
  FormatFuncKind,
  PrismaTypeKind,
  VueArrayInputComponentKind,
  VueInputComponentKind,
} from '../base';
import * as Type from '@sinclair/typebox';
import type { MultiSelectProps } from 'primevue/multiselect';
import type { SelectProps } from 'primevue/select';
import { titleCase } from 'scule';
import { h } from 'vue';

// Extend vendor enum because of some weird problem with StaticDecode
declare module '@sinclair/typebox' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TEnum<T>
    extends SimpleFormattable,
      InputtableSchema<SelectProps>,
      ArrayInputtableSchema<MultiSelectProps> {}
}

export { type TEnum, type TEnumValue } from '@sinclair/typebox';

export const enumRichOpts = {
  [FormatFuncKind]: (value, _, schema) => formatEnumValue(schema, value),
  [PrismaTypeKind]: 'NAME_PLACEHOLDER',
  [VueInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/select'),
    (C) => (props) =>
      h(C, {
        inputId: props.id,
        options: props.schema.anyOf.map((v) => ({
          value: v.const,
          label: genLiteralSchemaTitle(v),
        })),
        optionLabel: 'label',
        optionValue: 'value',
        appendTo:
          'body' /* https://github.com/primefaces/primeng/issues/10992, https://github.com/primefaces/primeng/issues/11046 */,
        showClear: props.nullable,
        ...props,
        id: `${props.id}_wrapper`,
      }),
  ),
  [VueArrayInputComponentKind]: createAsyncComponentWrapper(
    () => import('primevue/multiselect'),
    (C) => (props) =>
      h(C, {
        showToggleAll: false,
        showClear: true,
        inputId: props.id,
        options: props.schema.items.anyOf.map((v: Type.TLiteral<string | number>) => ({
          value: v.const,
          label: genLiteralSchemaTitle(v),
        })),
        optionLabel: 'label',
        optionValue: 'value',
        ...props,
        id: `${props.id}_wrapper`,
      }),
  ),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies RichTypeOpts<Type.TEnum<any>>;

export const Enum = <T extends Record<string, string | number>>(e: T, opts?: Type.SchemaOptions): Type.TEnum<T> =>
  createType(Type.Enum(e, opts), enumRichOpts);

export const formatEnumValue = <T extends Record<string, string | number>>(
  schema: Type.TEnum<T>,
  value: Type.StaticDecode<Type.TEnum<T>>,
) => schema.anyOf.find((s) => s.const === value)?.title ?? titleCase(String(value));
