import { R } from '../../vendor/remeda';
import * as Type from '../vendorType';
import { pascalCase, snakeCase } from 'scule';
import type { PascalCase, SnakeCase } from 'type-fest';

export const SnakeCaseKeysTransform = <T extends Record<string, Type.TSchema>, K extends string = keyof T & string>(
  schema: Type.TObject<T>,
  options?: Type.ObjectOptions,
): Type.TTransform<
  Type.TObject<{ [Key in K as SnakeCase<Key>]: T[Key] }>,
  { [Key in K]: Type.StaticDecode<T[Key]> }
> => {
  const encodeMap = R.mapValues(schema.properties, (_, k) => snakeCase(k)) as Record<string, string>;
  const decodeMap = R.invert(encodeMap);

  return Type.Transform(
    Type.Object(R.mapKeys(schema.properties, (k) => encodeMap[k as string] as string) as never, options),
  )
    .Decode((o) => R.mapKeys(o, (k) => decodeMap[k] as string))
    .Encode((o) => R.mapKeys(o, (k) => encodeMap[k] as string) as never) as never;
};

export const PascalCaseKeysTransform = <T extends Record<string, Type.TSchema>, K extends string = keyof T & string>(
  schema: Type.TObject<T>,
  options?: Type.ObjectOptions,
): Type.TTransform<
  Type.TObject<{ [Key in K as PascalCase<Key>]: T[Key] }>,
  { [Key in K]: Type.StaticDecode<T[Key]> }
> => {
  const encodeMap = R.mapValues(schema.properties, (_, k) => pascalCase(k)) as Record<string, string>;
  const decodeMap = R.invert(encodeMap);

  return Type.Transform(
    Type.Object(R.mapKeys(schema.properties, (k) => encodeMap[k as string] as string) as never, options),
  )
    .Decode((o) => R.mapKeys(o, (k) => decodeMap[k] as string))
    .Encode((o) => R.mapKeys(o, (k) => encodeMap[k] as string) as never) as never;
};
