/* eslint-disable @typescript-eslint/unbound-method */
import { Type, TypeGuard } from '@sinclair/typebox';

export * from './any';
export * from './array';
export * from './boolean';
export * from './enum';
export * from './literal';
export * from './null';
export * from './number';
export * from './object';
export * from './optional';
export * from './string';
export * from './union';
export {
  FormatRegistry,
  Hint,
  Kind,
  type SchemaOptions,
  type StaticDecode,
  type TComposite,
  type TIntersect,
  type TOmit,
  type TPick,
  type TRecord,
  type TRecursive,
  type TSchema,
  type TTransform,
  type TTuple,
  type TUndefined,
  type TUnknown,
  type TVoid,
  TypeRegistry,
} from '@sinclair/typebox';

export const {
  Composite,
  Intersect,
  KeyOf,
  Omit,
  Partial,
  Pick,
  Record,
  Recursive,
  Transform,
  Tuple,
  Undefined,
  Unknown,
  Void,
} = Type;
export const { IsIntersect, IsRecord, IsRecursive, IsTuple, IsUndefined, IsUnknown, IsVoid, IsTransform } = TypeGuard;
