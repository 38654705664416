import { log } from '../../utils/log';
import { R } from '../../vendor/remeda';
import type { RichTypeOpts } from '../base';
import { createType, LogTransformKind } from '../base';
import * as Type from '@sinclair/typebox';

export { type ObjectOptions, type TObject, type TProperties } from '@getmo/common/vendor/typebox';

export const { IsObject } = Type.TypeGuard;

const richOpts = {
  [LogTransformKind]: (value, schema) => {
    if (!R.isPlainObject(value)) {
      return value;
    }

    const result: Record<string, unknown> = {};
    for (const [key, v] of R.entries(value)) {
      const s = schema.properties[key];
      result[key] = s ? log.cleanExtraValue(s, v) : v;
    }

    return result;
  },
} satisfies RichTypeOpts<Type.TObject>;
const ObjectType = <T extends Record<string, Type.TSchema>>(
  properties: T,
  opts?: Type.ObjectOptions,
): Type.TObject<T> => createType(Type.Object(properties, opts), richOpts);

export { ObjectType as Object };
