import type { BankStatement, Document } from '@getmo/common/prisma';
import { $Enums } from '@getmo/common/prisma';
import { DF } from '@getmo/common/vendor/date-fns';
import { R } from '@getmo/common/vendor/remeda';

export const getRequiredBankStatementMonthCount = (legalType: $Enums.LegalType | null) => {
  switch (legalType) {
    case null:
    case $Enums.LegalType.personal:
      return 6;
    case $Enums.LegalType.corporate:
      return 12;
  }
};

export const getBankStatementsPeriod = (submittedAt: Date, legalType: $Enums.LegalType | null) => {
  const startOfMonth = DF.startOfMonth(submittedAt);
  const start = DF.sub(startOfMonth, { months: getRequiredBankStatementMonthCount(legalType) });
  const end = DF.endOfDay(DF.sub(startOfMonth, { days: 1 }));

  return { start, end };
};

type BankStatementBase = Pick<BankStatement, 'startDate' | 'endDate'>;

export const fillMissingBankStatementsForAccount = <T extends BankStatementBase>(
  statements: T[],
  legalType: $Enums.LegalType | null,
  submittedAt: Date,
) => {
  const period = getBankStatementsPeriod(submittedAt, legalType);
  const mandatoryEndDate = DF.startOfDay(period.end);
  let date = period.start;

  type MissingStatement = Pick<BankStatement, 'startDate' | 'endDate'>;
  type MaybeStatement = T | MissingStatement;

  const result: MaybeStatement[] = [];
  const pushMissingPeriods = (endDate: Date) => {
    while (date < endDate && date <= mandatoryEndDate) {
      const missingEndDate = DF.min([DF.startOfDay(DF.endOfMonth(date)), endDate]);
      result.push({ startDate: date, endDate: missingEndDate });
      date = DF.add(missingEndDate, { days: 1 });
    }
  };

  for (const s of R.sortBy(statements, (x) => x.startDate)) {
    pushMissingPeriods(s.startDate);
    result.push(s);
    if (date <= s.endDate) {
      date = DF.add(s.endDate, { days: 1 });
    }
  }
  pushMissingPeriods(mandatoryEndDate);

  return result;
};

export const isPdfResaved = (doc: Pick<Document, 'pdfCreator' | 'pdfProducer'>) => {
  for (const meta of [doc.pdfCreator, doc.pdfProducer].filter(R.isNonNullish).map((m) => m.toLowerCase())) {
    if (meta.includes('microsoft') && (meta.includes('word') || meta.includes('excel'))) {
      return true;
    }
    if (meta.includes('libreoffice')) {
      return true;
    }
    if (meta.includes('coreldraw')) {
      return true;
    }
  }
  return false;
};
