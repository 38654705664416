import { createEndpoint } from '@getmo/common/endpoint';
import { T } from '@getmo/common/typebox';

export const sendOtpEndpoint = createEndpoint({
  anonymous: true,
  method: 'post',
  path: '/login/otp/send',
  body: T.Object({ phone: T.String() }),
  response: T.Object({
    sessionId: T.Optional(T.String()),
  }),
});

export const checkOtpEndpoint = createEndpoint({
  anonymous: true,
  method: 'post',
  path: '/login/otp/check',
  body: T.Object({
    phone: T.String(),
    sessionId: T.Optional(T.String()),
    code: T.String(),
    fp: T.Optional(T.String()),
  }),
  response: T.Object({
    token: T.String(),
  }),
});
