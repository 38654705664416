import { Alert, Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { StepContent } from '../../../components/StepContent/StepContent';
import { StepSubmitButton } from '../../../components/StepSubmitButton/StepSubmitButton';
import { setAccessToken } from '../../../store/reducers/actions';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { phone } from '../../../utils/phone';
import PhoneCode from '../../components/PhoneCode';
import { useApiErrors } from '../../hooks/useApiErrors';
import { useNextStep } from '../../hooks/useNextStep';
import { useThemeQuery } from '../../hooks/useThemeQuery';
import { Path } from '../../routes/path';
import { useBrandSettings, usePhoneCode } from '../../hooks/useBrandSettings';
import { callApi, ExceptionResponse } from '../../../api';
import { checkOtpEndpoint, sendOtpEndpoint } from '@getmo/onboarding/schemas/endpoints/auth';
import { getFingerprint } from '@getmo/common/vendor/@thumbmarkjs/thumbmarkjs';

export const PhoneVerification: FC = () => {
  const { phoneNumber, sessionId } = useAppSelector((state) => state.account);
  const sessionIdRef = useRef(sessionId);

  if (!phoneNumber) {
    return <Navigate to={Path.AccountPhoneStarter} replace />;
  }

  const phoneCode = usePhoneCode();
  const brandSettings = useBrandSettings();
  const title = [
    <>Enter the 6-digit OTP&nbsp;code</>,
    brandSettings.region === 'ph' ? <>sent to your phone via SMS</> : <>sent to your Whatsapp</>,
  ];
  const subtitle = [
    <>
      We sent it to +{phoneCode} {phone.prettify(phoneNumber)}{' '}
      <Box
        component={Link}
        to={Path.AccountPhoneStarter}
        sx={{
          '&, &:visited': {
            textDecoration: 'none',
            color: 'primary.main',
          },
        }}
      >
        Change&nbsp;number
      </Box>
    </>,
  ];

  interface InitialValues {
    phoneCode: string[];
  }

  const initialValues: InitialValues = { phoneCode: [] };
  const [submitting, setSubmitting] = useState(false);
  const [timer, setTimer] = useState(0);
  const { goToNextStep } = useNextStep();
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const { isExtraSmall } = useThemeQuery();

  const resendOTP = useCallback(async () => {
    setTimer(120);
    const res = await callApi(sendOtpEndpoint, {
      body: { phone: phone.toApiValue(phoneNumber) },
    });
    sessionIdRef.current = res.sessionId;
  }, [timer]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined = undefined;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  const [isApplicationInUnderwriting, setIsApplicationInUnderwriting] = useState(false);
  const onSubmit = async ({ phoneCode }: InitialValues, helpers?: FormikHelpers<InitialValues>) => {
    try {
      setError('');
      setSubmitting(true);

      const { token } = await callApi(checkOtpEndpoint, {
        body: {
          phone: phone.toApiValue(phoneNumber),
          sessionId: sessionIdRef.current,
          code: phoneCode.join(''),
          fp: await getFingerprint(),
        },
      });
      dispatch(setAccessToken(token));
      goToNextStep();
    } catch (e) {
      if (e instanceof ExceptionResponse && e.status === 409) {
        setIsApplicationInUnderwriting(true);
        return;
      }
      const { error } = useApiErrors(e);
      setError(error);
      helpers?.resetForm();
    } finally {
      setSubmitting(false);
    }
  };
  const { applicationInUnderwritingText = 'Our account managers will contact you soon!' } = useBrandSettings();

  useEffect(() => {
    if (!('OTPCredential' in window)) return;

    const webOtpAbortController = new AbortController();
    navigator.credentials
      .get({
        otp: { transport: ['sms'] },
        signal: webOtpAbortController.signal,
      } as never)
      .then((otp) => {
        if (!otp) return;
        onSubmit({ phoneCode: (otp as unknown as { code: string }).code.split('') });
      })
      .catch((err) => {
        if (webOtpAbortController.signal.aborted) return;
        if (err instanceof DOMException && err.name === 'InvalidStateError') return;
        console.error(err);
      });

    return () => webOtpAbortController.abort();
  }, []);

  return (
    <StepContent title={title} subtitle={subtitle} width="extended">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container direction="column" gap={3} alignItems="center" justifyContent="center">
              <PhoneCode
                name="phoneCode"
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                disabled={submitting}
              />
              {error && (
                <Alert
                  severity="error"
                  sx={{ width: '100%', maxWidth: isExtraSmall ? '280px' : '328px' }}
                  data-testid="alert-error"
                >
                  {error}
                </Alert>
              )}
              <StepSubmitButton
                disabled={!!timer}
                isSubmitting={submitting}
                fullWidth
                type="button"
                text={timer ? `Resend (${timer})` : 'Resend'}
                onClick={resendOTP}
                sx={{ maxWidth: '416px' }}
              />
            </Grid>

            <Dialog keepMounted open={isApplicationInUnderwriting} maxWidth={'sm'}>
              <DialogTitle textAlign="center">Your application is being reviewed</DialogTitle>
              <DialogContent>
                <Typography textAlign="center" fontWeight={500}>
                  {applicationInUnderwritingText}
                </Typography>
              </DialogContent>
            </Dialog>
          </Form>
        )}
      </Formik>
    </StepContent>
  );
};
